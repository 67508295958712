import {cdnBase} from '../../const';

export default {
    ru_ru: {
        langKeys: {
            en_us: 'Английский (US)',
            en_ca: 'Английский (CA)',
            en_gb: 'Английский (UK)',
            en_au: 'Английский (AU)',
            fr_fr: 'Франция',
            nl_nl: 'Нидерланды',
            de_de: 'Германия',
            it_it: 'Италия',
            cz_cz: 'Республика Чехия',
            hu_hu: 'Болгария',
            pl_pl: 'Польша',
            ro_ro: 'Румыния',
            sk_sk: 'Словакия',
            mx_mx: 'Мексика',
            es_es: 'Испания',
            tr_tr: 'Турция',
            hr_hr: 'Хорватия',
            ru_ru: 'Россия',
        },
        interstitial: {
            heading: 'Вы покидаете сайт Cool Maker',
            copy: `Нажав «продолжить», вы покинете этот веб-сайт и перейдете на сторонний сайт, который регулируется другими условиями и политикой конфиденциальности.`,
            continue: 'Продолжить',
            cancel: 'Отмена',
        },
        ga: {
            copy: 'Мы используем файлы cookie на этом сайте, чтобы улучшить его работу. Используя этот сайт, вы соглашаетесь на использование файлов cookie. Наша Политика использования файлов cookie объясняет, что такое файлы cookie, как мы используем файлы cookie, как третьи стороны, с которыми мы можем сотрудничать, могут использовать файлы cookie в своих Сервисах, ваш выбор в отношении файлов cookie и дополнительную информацию о файлах cookie.',
            continue: 'Продолжить',
            disable: 'Отключить cookies',
        },
        brand: 'Кул Мэйкер',
        applinks: {
            apple: 'ССЫЛКА НА ПРИЛОЖЕНИЕ APPLE',
            google: 'ССЫЛКА НА ПРИЛОЖЕНИЕ ANDROID',
        },
        chooseRegion: '	Выбрать язык',
        loading: 'Загрузка',
        select: 'Выбирать',
        clearAll: 'Очистить все',
        filters: 'Фильтры',
        details: 'Описание',
        instructions: 'Скачать инструкцию',
        videoInstructions: 'video Instructions',
        warnings: {
            enableIframes:
                'Вы должны включить iFrames для просмотра этого видео',
        },
        helmet: {
            siteTitle: ' | Кул Мэйкер ',
        },
        navigation: {
            youtube_link: 'https://www.youtube.com/c/CoolMaker',
            youtube_alt: 'YouTube Icon',
            instagram_link: 'https://www.instagram.com/coolmaker/',
            instagram_alt: 'Instagram Icon',
            fb_link: 'https://www.facebook.com/CoolMakerOfficial',
            fb_alt: 'Facebook Icon',

            mainmenu: [
                {
                    type: 'internal',
                    href: '/',
                    display: 'Главная страница',
                },
                {
                    type: 'internal',
                    href: '/videos',
                    display: 'Видео',
                },
                {
                    type: 'internal',
                    href: '/toys',
                    display: 'Товары',
                },
                {
                    type: 'internal_hash',
                    href: '#wheretobuy',
                    display: 'Где купить',
                },
            ],
        },
        meta: {
            title: 'Мечтай. Создавай. Дай волю фантазии и стань творцом!',
            tags: 'кул мэйкер, творчество, детские швейные наборы, декоративно-прикладное искусство, детские игрушки, игрушки для девочек, игрушка, рукоделие, дополнительные наборы, Гоу Глэм штампы для ногтей, мини-наборы Гоу Глэм, Гоу Глэм, Куми Криэйтор 2 в 1, набор для браслетов Куми Криэйтор, мини-набор Куми Криэйтор, Куми Криэйтор, набор для браслетов, набор для браслетов дружбы, штампы для ногтей, набор для создания украшений, игрушки для девочек, детская швейная машинка',
        },
        footer: {
            brand: 'Спин Мастер',
            contactUs: 'Контакты',
            privacy: 'Политика конфиденциальности',
            terms: 'Условия использования',
            support: '',
            copy: 'Spin Master Ltd. Все права защищены.',
            contactLink:
                'https://www.spinmaster.com/contact-us.php?userLoc=us&display=customer_service_info',
            privacyLink:
                'https://www.spinmaster.com/privacy-policy.php?userLoc=us',
            termsLink:
                'https://www.spinmaster.com/terms-of-service.php?userLoc=us',
            supportLink: 'https://www.spinmaster.com/support.php?userLoc=us',
        },
        home: {
            title: 'Главная страница (на главную)',
            whereToBuy: 'Где купить',
            productCaro: 'Начать творчество',
            viewAll: 'Посмотреть все',
            sections: {
                retailers: {
                    header: 'Где купить',
                    retailers: [
                        {
                            name: 'MyToys',
                            href: 'https://www.mytoys.ru/Cool-Maker/KID/ru-mt.br.10111/',
                            img: `${cdnBase}retailers/myToys_RU_claim_FINAL.jpg`,
                        },
                        {
                            name: 'Detskiy Mir',
                            href: 'https://www.detmir.ru/catalog/index/name/sortforbrand/brand/19112/',
                            img: `${cdnBase}retailers/DM_logo_1.png`,
                        },
                        {
                            name: 'Toy.RU',
                            href: 'https://www.toy.ru/search/index.php?q=cool+maker',
                            img: `${cdnBase}retailers/toysru.png`,
                        },
                        {
                            name: 'Дочки-сыночки',
                            href: 'https://www.dochkisinochki.ru/icatalog/search.php?q=cool+maker&ss%5B0%5D=on',
                            img: `${cdnBase}retailers/Dochkisinochki.png`,
                        },
                        {
                            name: 'Ozron',
                            href: 'https://www.ozon.ru/brand/cool-maker-149657627/',
                            img: `${cdnBase}retailers/OZON_Logo_v09-07-04.png`,
                        },
                        {
                            name: 'Kari Kids',
                            href: 'https://kari.com/product/85407170',
                            img: `${cdnBase}retailers/kari-logo_kids.jpg`,
                        },
                        {
                            name: 'Wildberries',
                            href: 'https://www.wildberries.ru/catalog/0/search.aspx?search=cool%20maker&xsearch=true',
                            img: `${cdnBase}retailers/wildberries.jpg`,
                        },
                    ],
                },
            },
            slides: [
                {
                    class: 'half_split',
                    heading: 'Комбинируй цвета и создай свой модный маникюр!',
                    subheading:
                        'Так много ярких цветов и крутых дизайнов, чтобы создать свой уникальный стиль!',
                    img: `${cdnBase}homepage/banners/Homepage-Header-Rotator3.png`,
                    alt: 'go glam girl',
                    logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
                    logo_alt: 'go glam logo',
                    ctas: [
                        {
                            cta_type: "internal",
                            cta_label: 'Подробнее',
                            cta_link: '/toys/778988285367',
                        },
                    ],
                },
                {
                    class: 'half_split',
                    heading: 'Салон красоты у тебя дома!',
                    subheading:
                        'Маникюрный салон Гоу Глэм позволяет легко наносить идеальный рисунок на ногти.',
                    img: `${cdnBase}homepage/banners/Homepage-Header-Rotator1_resize.png`,
                    alt: 'Go Glam Nail Stamper',
                    logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
                    logo_alt: 'go glam logo',
                    ctas: [
                        {
                            cta_type: "internal",
                            cta_label: 'Подробнее',
                            cta_link: '/toys/778988285367',
                        },
                    ],
                },
                {
                    class: 'half_split',
                    classImg: 'add_push',
                    heading: 'Уникальный маникюр за считанные минуты!',
                    subheading: 'Просто поставь, накрась и нажми',
                    img: `${cdnBase}homepage/banners/Homepage-Header-Rotator2_resize.png`,
                    alt: 'Custom Manicures',
                    logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
                    logo_alt: 'go glam logo',
                    ctas: [
                        {
                            cta_type: "internal",
                            cta_label: 'Подробнее',
                            cta_link: '/toys/778988285367',
                        },
                    ],
                },
            ],
            promoBlocks: {
                hp_firstblock_single: {
                    class: "hollywoodHair_Section",
                    title: 'Набор Голливудские локоны!',
                    desc: 'Придумывай и создавай яркие прически с набором Кул Мэйкер Голливудские локоны!',
                    hp_video: `${cdnBase}videos/videosList/F20+CoolMaker+HollywoodHair+30sTVC+FINAL+720p+noslate.mp4`,
                    hp_video_img: `${cdnBase}videos/videoThumbs/CoolMaker-HollywoodHair-TVC-Thumbnail-Homepage.png`,
                    hp_video_alt: 'Hollywood hair video',
                    logo: `${cdnBase}homepage/promoblocks/HollywoodHair-Logo.png`,
                    alt: 'Hollywood Hair logo',
                    imgClass: 'imageclass',
                    img: `${cdnBase}homepage/promoblocks/HollywoodHair-Image.png`,
                    ctas: [
                        {
                            label: 'Подробнее',
                            link: '778988302798',
                        },
                    ],
                },
                hp_secondblock_single: {
                    title: 'Куми набор для творчества Студия 2 в 1',
                    desc: 'Создавай прекрасные браслеты и колье за считанные минуты с Куми набором для творчества Студия 2 в 1!',
                    logo: `${cdnBase}homepage/promoblocks/KumiKreator-Logo.png`,
                    alt: 'Kumi Kreator logo',
                    img: `${cdnBase}homepage/promoblocks/KumiKreator-Image.png`,
                    cta: 'Подробнее',
                    productId: '778988275191',
                },
                hp_thirdblock_double: [
                    {
                        title: 'Шей без ниток!',
                        img: `${cdnBase}homepage/promoblocks/sew_wo_thread.jpg`,
                        desc: 'Создавай удивительные вещицы со Швейным набором от Кул Мэйкер',
                        cta: 'Подробнее',
                        productId: '778988311424',
                    },
                ],
            },
        },
        collection: {
            title: 'Коллекция Кул Мэйкер',
            nextPage: 'Следующая страница',
            prevPage: 'Предыдущая страница',
            all: 'Все товары',
            wantIt: 'Хочу этоt',
            haveIt: 'Купить это',
            flipIt: 'Факты',
            scent: 'Ароматы',
            rarity: 'Редкость',
            color: 'Цвет',
            showing: 'Демонстрация',
            filters: ['Аромат', 'Редкость', 'Цвет'],
        },
        support: {
            title: 'Поддержка',
            subTitle: 'Поддержка',
        },
        toys: {
            title: 'Товары',
            subTitle: 'Товары',
            filterProd: 'фильтровать товары',
            learn: 'узнать',
            more: 'больше',
            goGlam: 'Гоу Глэм',
            handcrafted: 'Сделано вручную',
            jojo: 'Джо Джо',
            kumi: 'Куми Криэйтор',
            pottery: 'Керамика',
            sewing: 'Шитье',
            tidydye: 'Аккуратное окрашивание',
            clearall: 'Сбросить все фильтры',
        },
        toy: {
            title: 'Игрушка',
            subTitle: 'Игрушка',
            backToToys: 'Назад к игрушкам',
            features: 'Характеристики',
            image: 'Картинка',
            contents: 'Содержание',
            whereToBuy: 'Где купить',
            instructions: '	Инструкции',
            video: 'Видео',
            videoInstructions: 'Видео-инструкции',
            brandSite: 'сайт бренда',
            buyNow: 'Купить сейчас',
            retailers: 'Магазины',
            coolMakerSalon: 'Маникюрный салон Кул Мэйкер',
            bannerImg: `${cdnBase}toy/ProductPage-DSKTP-Header_Fade.png`,
            bannerImg_mobile: `${cdnBase}toy/ProductPage-MBL-Header-2.jpg`,
            bannerAlt: 'Баннер страницы игрушек',
        },
        videos: {
            title: 'Видео',
            subTitle: 'Видео',
            bannerImg: `${cdnBase}videos/banners/VideoPage-DSKTP-Header_Fade.png`,
            bannerImg_mobile: `${cdnBase}videos/banners/VideoPage-MBL-Header-2.jpg`,
            bannerAlt: 'Video Page Banner',
            playVideo: 'Смотреть видео',
            playButton_Alt: 'play button',
            downArrow_Alt: 'down arrow',
            defaultVideoIndex: 0,
            categories: 'Categories',
            catList: ['Все', 'Реклама', 'Блогеры'],
            prodList: [
                'Все',
                'Сью Кул',
                'Керамика',
                'Go Glam',
                'Hollywood Hair',
            ],
            videoList: [
                {
                    type: `commercial`,
                    thumb: `CoolMaker-HollywoodHair-TVC-Thumbnail-Videopage.jpg`,
                    video: `${cdnBase}videos/videosList/F20+CoolMaker+HollywoodHair+30sTVC+FINAL+720p+noslate.mp4`,
                    title: `Hollywood Hair Styled by You`,
                    brand: `hollywoodhair`,
                    categories: ['All', `Все`, `Hollywood Hair`],
                },
                {
                    type: `tvc`,
                    thumb: `CoolMaker-GoGlam-TVC-Thumbnail-Videopage.jpg`,
                    video: `${cdnBase}videos/videosList/F20+CoolMaker+GoGlamDeluxe+30sTVC+FINAL+720p+noslate.mp4`,
                    title: `Go Glam Instant Salon Style`,
                    brand: `goglam`,
                    categories: ['All', `Все`, 'Go Glam'],
                },
                {
                    type: `howto`,
                    thumb: `CoolMaker_HollywoodHair_Troubleshooting.jpg`,
                    video: `${cdnBase}videos/videosList/HH_Troubleshooting_Final.mp4`,
                    title: `Hollywood Hair Troubleshooting`,
                    brand: `hollywoodhair`,
                    categories: ['All', `Все`, 'Hollywood Hair'],
                },
                {
                    type: `howto`,
                    thumb: `CoolMaker_HollywoodHair_Starstruck.jpg`,
                    video: `${cdnBase}videos/videosList/HH_StarStruckRefill_Final.mp4`,
                    title: `Starstruck Refill Pack How-To`,
                    brand: `hollywoodhair`,
                    categories: ['All', `Все`, 'Hollywood Hair'],
                },
                {
                    type: `howto`,
                    thumb: `CoolMaker_HollywoodHair_PartyPop.jpg`,
                    video: `${cdnBase}videos/videosList/HH_PartyPopRefill_Final.mp4`,
                    title: `Party Pop Refill Pack How-To`,
                    brand: `hollywoodhair`,
                    categories: ['All', `Все`, 'Hollywood Hair'],
                },
                {
                    type: `howto`,
                    thumb: `CoolMaker_Template_Curls.jpg`,
                    video: `${cdnBase}videos/videosList/HH_MakingCurls_Final.mp4`,
                    title: `How-To Make Curls`,
                    brand: `hollywoodhair`,
                    categories: ['All', `Все`, 'Hollywood Hair'],
                },
                {
                    type: `howto`,
                    thumb: `CoolMaker_HollywoodHair_FULL.jpg`,
                    video: `${cdnBase}videos/videosList/HH_HowTo_Final.mp4`,
                    title: `Hollywood Hair How-To`,
                    brand: `hollywoodhair`,
                    categories: ['All', `Все`, 'Hollywood Hair'],
                },
                {
                    type: `howto`,
                    thumb: `CoolMaker_HollywoodHair_Clipping+Storage.jpg`,
                    video: `${cdnBase}videos/videosList/HH_ClippingIntoHair-Storage_Final.mp4`,
                    title: `Clipping into Hair and Storage`,
                    brand: `hollywoodhair`,
                    categories: ['All', `Все`, 'Hollywood Hair'],
                },
                {
                    type: 'howto',
                    thumb: 'kumi-how-to.jpg',
                    video: `${cdnBase}videos/videosList/F19+CM+KumiKreator2-in-1+UpdatedPkg+How-To+FINAL+720p+noendslate.mp4`,
                    title: 'KumiKreator How To',
                    brand: 'goglam',
                    categories: ['All', 'Все', 'Go Glam'],
                },
                {
                    type: 'howto',
                    thumb: 'go-glam-how-to.jpg',
                    video: `${cdnBase}videos/videosList/Cool+Maker_Go-Glam_Final_16x9_190809-_NO+ENDSLATE.mp4`,
                    title: 'Go Glam How-To Video',
                    brand: 'goglam',
                    categories: ['All', 'Все', 'Go Glam'],
                },
                {
                    type: 'commercial',
                    thumb: 'sew_tvc_15.jpg',
                    video: `${cdnBase}videos/videosList/sew_tvc_15.mp4`,
                    title: "Sew N' Style Machine",
                    brand: 'sewing',
                    categories: ['All', 'Все', 'Сью Кул'],
                },
                {
                    type: 'commercial',
                    thumb: 'pottery_tvc_15.jpg',
                    video: `${cdnBase}videos/videosList/pottery_tvc_15.mp4`,
                    title: 'Pottery Studio',
                    brand: 'Керамика',
                    categories: ['All', 'Все', 'Керамика'],
                },
                {
                    type: 'howto',
                    thumb: 'sew_n_style_how_to.jpg',
                    video: `${cdnBase}videos/videosList/sew_n_style_how_to.mp4`,
                    title: "Sew N' Style Machine",
                    brand: 'sewing',
                    categories: ['All', 'Все', 'Керамика'],
                },
                {
                    type: 'howto',
                    thumb: 'cell_phone_speaker_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/cell_phone_speaker_how_to_2017.mp4`,
                    title: 'Pottery Studio - How To Make A Cell Phone Speaker And More',
                    brand: 'Керамика',
                    categories: ['All', 'Все', 'Керамика'],
                },
                {
                    type: 'howto',
                    thumb: 'jewelry_holder_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/jewelry_holder_how_to_2017.mp4`,
                    title: 'Pottery Studio - How To Make A Jewelry Holder',
                    brand: 'Керамика',
                    categories: ['All', 'Все', 'Керамика'],
                },
                {
                    type: 'howto',
                    thumb: 'pottery_cool_tips_tricks_2017.jpg',
                    video: `${cdnBase}videos/videosList/pottery_cool_tips_tricks_2017.mp4`,
                    title: 'Pottery Studio - Tips and Tricks',
                    brand: 'Керамика',
                    categories: ['All', 'Все', 'Керамика'],
                },
                {
                    type: 'howto',
                    thumb: 'blingy_bank_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/blingy_bank_how_to_2017.mp4`,
                    title: 'Pottery Studio - How To Make A Blinky Bank',
                    brand: 'Керамика',
                    categories: ['All', 'Все', 'Керамика'],
                },
            ],
        },
        nomatch: {
            title: 'Страница не найдена',
        },
    },
};