import {cdnBase} from '../../const';

export default {
    nl_nl: {
        langKeys: {
            en_us: 'Engels (US)',
            en_ca: 'Engels (CA)',
            en_gb: 'Engels (UK)',
            en_au: 'Engels (AU)',
            fr_fr: 'Frankrijk',
            nl_nl: 'Nederland',
            de_de: 'Duitsland',
            it_it: 'Italië',
            cz_cz: 'Tsjechië',
            hu_hu: 'Hongarije',
            pl_pl: 'Polen',
            ro_ro: 'Roemenië',
            sk_sk: 'Slowakije',
            mx_mx: 'Mexico',
            es_es: 'Spanje',
            tr_tr: 'Turkije',
            hr_hr: 'Kroatië',
            ru_ru: 'Rusland',
        },
        interstitial: {
            heading: 'Je verlaat de Cool Maker-website.',
            copy: `Door op "doorgaan" te klikken verlaat je deze website en kom je terecht op een site van een derde partij, waarop andere voorwaarden en privacybeleidsregels van toepassing zijn.`,
            continue: 'Doorgaan',
            cancel: 'Annuleren',
        },
        ga: {
            copy: 'We gebruiken cookies op deze site om je ervaring te verbeteren. Door deze site te gebruiken, stem je in met het gebruik van cookies. Ons cookiebeleid legt uit wat cookies zijn, hoe we cookies gebruiken, hoe derde partijen waarmee we samenwerken cookies zouden kunnen gebruiken, je keuzes met betrekking tot cookies en meer informatie over cookies. ',
            continue: 'Doorgaan',
            disable: 'Schakel cookies uit ',
        },
        brand: 'Cool Maker',
        applinks: {
            apple: 'LINK NAAR APPLE APP',
            google: 'LINK NAAR GOOGLE APP',
        },
        chooseRegion: 'Kies een taal',
        loading: 'Bezig met laden',
        select: 'Selecteren',
        clearAll: 'Wis alles',
        filters: 'Filters',
        details: 'Details',
        instructions: 'download instructies',
        videoInstructions: 'video-instructies',
        warnings: {
            enableIframes:
                'Je moet iFrames inschakelen om deze video te bekijken',
        },
        helmet: {
            siteTitle: ' | Cool Maker ',
        },
        navigation: {
            youtube_link: 'https://www.youtube.com/c/CoolMaker',
            youtube_alt: 'YouTube Icon',
            instagram_link: 'https://www.instagram.com/coolmaker/',
            instagram_alt: 'Instagram Icon',
            fb_link: 'https://www.facebook.com/CoolMakerOfficial',
            fb_alt: 'Facebook Icon',

            mainmenu: [
                {
                    type: 'internal',
                    href: '/',
                    display: 'Huis',
                },
                {
                    type: 'internal',
                    href: '/videos',
                    display: 'Videos',
                },
                {
                    type: 'internal',
                    href: '/toys',
                    display: 'Producten',
                },
                {
                    type: 'internal_hash',
                    href: '#wheretobuy',
                    display: 'Waar te koop',
                },
                {
                    type: 'external',
                    href: 'https://spinmastersupport.helpshift.com/a/cool-maker/?p=web',
                    display: 'Ondersteuning',
                },
            ],
        },
        meta: {
            title: 'Bedenk het. Creëer het. Laat je creativiteit de vrije loop en word een Cool Maker!',
            tags: 'coolmaker, maker, naaisets voor kinderen, knutselen, kinderspeelgoed, speelgoed voor meisjes, speelgoed, cool maker, cool maker refill, go glam nail stamper, go glam nail stamper refill, go glam, 2 in 1 kumikreator, kumikreator bracelet maker, kumikreator vulling, kumikreator, armband maker, vriendschap armband kit, nagel stamper, sieraden maken kit, speelgoed voor meisjes, naaimachine voor kinderen',
        },
        footer: {
            brand: 'Spin Master',
            contactUs: 'Neem contact op',
            privacy: 'Privacybeleid',
            terms: 'Servicevoorwaarden',
            support: 'Ondersteuning',
            copy: 'Spin Master Ltd. Alle rechten voorbehouden.',
            contactLink:
                'https://www.spinmaster.com/contact-us.php?userLoc=us&display=customer_service_info',
            privacyLink:
                'https://www.spinmaster.com/privacy-policy.php?userLoc=us',
            termsLink:
                'https://www.spinmaster.com/terms-of-service.php?userLoc=us',
            supportLink: 'https://www.spinmaster.com/support.php?userLoc=us',
        },
        home: {
            title: 'Home',
            whereToBuy: 'Waar te koop',
            productCaro: 'Begin met creëren ',
            viewAll: ' Bekijk alles',
            sections: {
                retailers: {
                    header: 'Waar te koop',
                    retailers: [
                        {
                            name: 'wehkamp',
                            href: '',
                            img: `${cdnBase}retailers/Wehkamp+logo.png`,
                        },
                        {
                            name: 'top 1 toys',
                            href: 'https://www.top1toys.nl/',
                            img: `${cdnBase}retailers/T1T+Lang+FC+geel+vlak.jpg`,
                        },
                        {
                            name: 'toy champ',
                            href: 'https://www.toychamp.nl/',
                            img: `${cdnBase}retailers/toychamp_champy.jpg`,
                        },
                        {
                            name: 'fun',
                            href: 'https://www.fun.be/',
                            img: `${cdnBase}retailers/FUN.BE.png`,
                        },
                        {
                            name: 'trafic',
                            href: '',
                            img: `${cdnBase}retailers/logo.png`,
                        },
                        {
                            name: 'speelgoed winkel.nl',
                            href: '',
                            img: `${cdnBase}retailers/speelgoedwinkel+logo.png`,
                        },
                        {
                            name: 'dreamland',
                            href: 'https://www.dreamland.be/e/nl/dl/',
                            img: `${cdnBase}retailers/DreamLandfond+Q+regenboog.jpg`,
                        },
                        {
                            name: 'euro shop',
                            href: '',
                            img: `${cdnBase}retailers/euroshop+logo.png`,
                        },
                        {
                            name: 'cora',
                            href: '',
                            img: `${cdnBase}retailers/CORA.png`,
                        },
                        {
                            name: 'carrefour',
                            href: '',
                            img: `${cdnBase}retailers/Carrefour+logo.png`,
                        },
                        {
                            name: 'jouets broze',
                            href: '',
                            img: `${cdnBase}retailers/Broze+logo+FR.jpg`,
                        },
                        {
                            name: 'cactus',
                            href: '',
                            img: `${cdnBase}retailers/Cactus_logo.svg`,
                        },
                        {
                            name: 'bol.com',
                            href: 'https://www.bol.com/nl/',
                            img: `${cdnBase}retailers/bolcom_logo_blauw_rgb.jpg`,
                        },
                        {
                            name: 'toys & toys',
                            href: '',
                            img: `${cdnBase}retailers/ATOYS%26TOYS+aangepast.svg`,
                        },
                        {
                            name: 'beeusaert braet',
                            href: '',
                            img: `${cdnBase}retailers/Beeusaert-Braet+logo.jpg`,
                        },
                    ],
                },
            },
            slides: [
                {
                    class: 'half_split',
                    heading: 'Mix en match manicures!',
                    subheading:
                        'Zoveel leuke en trendy ontwerpen die bij je eigen persoonlijke stijl passen!',
                    img: `${cdnBase}homepage/banners/Homepage-Header-Rotator3.png`,
                    alt: 'go glam girl',
                    logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
                    logo_alt: 'go glam logo',
                    ctas: [
                        {
                            cta_type: "internal",
                            cta_label: 'Leer meer',
                            cta_link: '/toys/778988285367',
                        },
                    ],
                },
                {
                    class: 'half_split',
                    heading: 'Haal de salon in huis!',
                    subheading:
                        'Go Glam Nagelsalon maakt het gemakkelijk om ontwerpen perfect op nagels te stempelen.',
                    img: `${cdnBase}homepage/banners/Homepage-Header-Rotator1_resize.png`,
                    alt: 'Go Glam Nail Stamper',
                    logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
                    logo_alt: 'go glam logo',
                    ctas: [
                        {
                            cta_type: "internal",
                            cta_label: 'Leer meer',
                            cta_link: '/toys/778988285367',
                        },
                    ],
                },
                {
                    class: 'half_split',
                    classImg: 'add_push',
                    heading: 'Manicures op maat in enkele minuten!',
                    subheading: 'Gewoon poppen, lakken en drukken. ',
                    img: `${cdnBase}homepage/banners/Homepage-Header-Rotator2_resize.png`,
                    alt: 'Custom Manicures',
                    logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
                    logo_alt: 'go glam logo',
                    ctas: [
                        {
                            cta_type: "internal",
                            cta_label: 'Leer meer',
                            cta_link: '/toys/778988285367',
                        },
                    ],
                },
            ],
            promoBlocks: {
                hp_firstblock_single: {
                    class: "hollywoodHair_Section",
                    title: 'Hollywood Hair Extension Maker!',
                    desc: 'Ontwerp en maak haarextensies met de Cool Maker Hollywood Hair Extension Maker!',
                    hp_video: `${cdnBase}videos/videosList/CoolMaker_GoGlam.mp4`,
                    hp_video_img: `${cdnBase}homepage/promoblocks/video_tvc.jpg`,
                    hp_video_alt: 'Hollywood hair video',
                    logo: `${cdnBase}homepage/promoblocks/HollywoodHair-Logo.png`,
                    alt: 'Hollywood Hair logo',
                    imgClass: 'imageclass',
                    img: `${cdnBase}homepage/promoblocks/HollywoodHair-Image.png`,
                    ctas: [
                        {
                            label: 'Leer meer',
                            link: '778988302798',
                        },
                    ],
                },
                hp_secondblock_single: {
                    title: '2-in-1 KumiKreator',
                    desc: 'Draai om voor je ogen prachtige vriendschapsarmbanden en -kettingen te maken met de Cool Maker 2-in-1 KumiKreator!',
                    logo: `${cdnBase}homepage/promoblocks/KumiKreator-Logo.png`,
                    alt: 'Kumi Kreator logo',
                    img: `${cdnBase}homepage/promoblocks/KumiKreator-Image.png`,
                    cta: 'Leer meer',
                    productId: '778988275191',
                },
                //hp_thirdblock_double: [
                // {
                //   title: "",
                //   img: `${cdnBase}homepage/promoblocks/sew_wo_thread.jpg`,
                //   desc: "",
                //   cta: "Leer meer",
                //   productId: "778988311424"
                // },
                // {
                //   title: "Pottenbakken gemakkelijk gemaakt!",
                //   img: `${cdnBase}homepage/promoblocks/easy_pottery.jpg`,
                //   desc: "Maak eenvoudig klei-creaties met de Cool Maker Pottery Studio!",
                //   cta: "Leer meer",
                //   productId: "778988225813"
                // }
                // ],
                hp_thirdblock_single: {
                    title: 'Pottenbakken gemakkelijk gemaakt!',
                    desc: 'Maak eenvoudig klei-creaties met de Cool Maker Pottery Studio!',
                    cta: 'Leer meer',
                    cta_link: 'https://www.youtube.com/c/CoolMaker',
                    img: `${cdnBase}homepage/promoblocks/easy_pottery.jpg`,
                    alt: '',
                },
                hp_fourthblock_single: {
                    title: 'Ga naar ons YouTube-kanaal voor meer DIY-inspiratie!',
                    desc: '',
                    cta: 'Laten we beginnen met maken',
                    cta_link: 'https://www.youtube.com/c/CoolMaker',
                    img: `${cdnBase}homepage/promoblocks/YouTubeImage.png`,
                    alt: 'Cool Maker Youtube images containing screenshots',
                },
            },
        },
        collection: {
            title: 'Cool Maker Collection',
            nextPage: 'Next Page',
            prevPage: 'Prev Page',
            all: 'All',
            wantIt: 'Want it',
            haveIt: 'Have it',
            flipIt: 'Facts',
            scent: 'Scent',
            rarity: 'Rarity',
            color: 'Color',
            showing: 'Showing',
            filters: ['scent', 'rarity', 'color'],
        },
        support: {
            title: 'Ondersteuning',
            subTitle: 'Ondersteuning',
        },
        toys: {
            title: 'Producten',
            subTitle: 'Producten',
            filterProd: 'Filter producten',
            learn: 'leer',
            more: 'meer',
            goGlam: 'Go Glam',
            handcrafted: 'Handgemaakt',
            jojo: 'Jo Jo',
            kumi: 'Kumi Kreator',
            pottery: 'Pottenbakken',
            sewing: 'ttt',
            tidydye: 'Tidy Dye',
            clearall: 'Wis alle filters',
        },
        toy: {
            title: 'Speelgoed',
            subTitle: 'Speelgoed',
            backToToys: 'Terug naar Speelgoed',
            features: 'Kenmerken',
            image: 'Afbeelding',
            contents: 'Inhoud',
            whereToBuy: 'Waar te koop',
            instructions: 'Instructies',
            video: 'Video',
            videoInstructions: 'Video-instructies',
            brandSite: 'brand site',
            buyNow: 'Koop nu',
            retailers: 'Winkeliers',
            coolMakerSalon: 'Cool Maker Nail Salon',
            bannerImg: `${cdnBase}toy/ProductPage-DSKTP-Header_Fade.png`,
            bannerImg_mobile: `${cdnBase}toy/ProductPage-MBL-Header-2.jpg`,
            bannerAlt: 'Toy Page Banner',
        },
        videos: {
            title: `Video's`,
            subTitle: `Video's`,
            bannerImg: `${cdnBase}videos/banners/VideoPage-DSKTP-Header_Fade.png`,
            bannerImg_mobile: `${cdnBase}videos/banners/VideoPage-MBL-Header-2.jpg`,
            bannerAlt: 'Video Page Banner',
            playVideo: 'Speel video af',
            playButton_Alt: 'play button',
            downArrow_Alt: 'down arrow',
            defaultVideoIndex: 0,
            categories: 'Categories',
            catList: ['All', 'Commercials', 'Influencer'],
            prodList: [
                /*'All', 'Sew Cool',*/ 'Pottery',
                'Go Glam',
                'Shimmer Me',
                'Hollywood Hair',
            ],
            videoList: [
                {
                    type: `howto`,
                    thumb: `body-art-thumb.jpg`,
                    video: `${cdnBase}videos/videosList/F21+CM+Shimmer+Me+Body+Art+30sTVC+SMSM21300H+FINAL.mp4`,
                    title: `Shimmer Me Body Art`,
                    brand: `shimmerme`,
                    categories: [`All`, `Shimmer Me`],
                },
                {
                    type: `commercial`,
                    thumb: `CoolMaker-HollywoodHair-TVC-Thumbnail-Videopage.jpg`,
                    video: `${cdnBase}videos/videosList/F20+CoolMaker+HollywoodHair+30sTVC+FINAL+720p+noslate.mp4`,
                    title: `Hollywood Hair Styled by You`,
                    brand: `hollywoodhair`,
                    categories: [`All`, `Hollywood Hair`],
                },
                {
                    type: `tvc`,
                    thumb: `CoolMaker-GoGlam-TVC-Thumbnail-Videopage.jpg`,
                    video: `${cdnBase}videos/videosList/F20+CoolMaker+GoGlamDeluxe+30sTVC+FINAL+720p+noslate.mp4`,
                    title: `Go Glam Instant Salon Style`,
                    brand: `goglam`,
                    categories: [`All`, 'Go Glam'],
                },
                {
                    type: `howto`,
                    thumb: `CoolMaker_HollywoodHair_Troubleshooting.jpg`,
                    video: `${cdnBase}videos/videosList/HH_Troubleshooting_Final.mp4`,
                    title: `Problemen met Hollywood Hair oplossen `,
                    brand: `hollywoodhair`,
                    categories: [`All`, 'Hollywood Hair'],
                },
                {
                    type: `howto`,
                    thumb: `CoolMaker_HollywoodHair_Starstruck.jpg`,
                    video: `${cdnBase}videos/videosList/HH_StarStruckRefill_Final.mp4`,
                    title: `Hoe werkt de Starstruck Navulverpakking`,
                    brand: `hollywoodhair`,
                    categories: [`All`, 'Hollywood Hair'],
                },
                {
                    type: `howto`,
                    thumb: `CoolMaker_HollywoodHair_PartyPop.jpg`,
                    video: `${cdnBase}videos/videosList/HH_PartyPopRefill_Final.mp4`,
                    title: `Hoe werkt de Party Pop Navulverpakking`,
                    brand: `hollywoodhair`,
                    categories: [`All`, 'Hollywood Hair'],
                },
                {
                    type: `howto`,
                    thumb: `CoolMaker_Template_Curls.jpg`,
                    video: `${cdnBase}videos/videosList/HH_MakingCurls_Final.mp4`,
                    title: `Hoe maak je krullen`,
                    brand: `hollywoodhair`,
                    categories: [`All`, 'Hollywood Hair'],
                },
                {
                    type: `howto`,
                    thumb: `CoolMaker_HollywoodHair_FULL.jpg`,
                    video: `${cdnBase}videos/videosList/HH_HowTo_Final.mp4`,
                    title: `Hoe werkt Hollywood Hair`,
                    brand: `hollywoodhair`,
                    categories: [`All`, 'Hollywood Hair'],
                },
                {
                    type: `howto`,
                    thumb: `CoolMaker_HollywoodHair_Clipping+Storage.jpg`,
                    video: `${cdnBase}videos/videosList/HH_ClippingIntoHair-Storage_Final.mp4`,
                    title: `Aan het haar vast klemmen en Opbergen`,
                    brand: `hollywoodhair`,
                    categories: [`All`, 'Hollywood Hair'],
                },
                {
                    type: 'howto',
                    thumb: 'kumi-how-to.jpg',
                    video: `${cdnBase}videos/videosList/F19+CM+KumiKreator2-in-1+UpdatedPkg+How-To+FINAL+720p+noendslate.mp4`,
                    title: 'Hoe werkt de KumiKreator',
                    brand: 'goglam',
                    categories: ['All', 'Go Glam'],
                },
                {
                    type: 'howto',
                    thumb: 'go-glam-how-to.jpg',
                    video: `${cdnBase}videos/videosList/Cool+Maker_Go-Glam_Final_16x9_190809-_NO+ENDSLATE.mp4`,
                    title: 'Go Glam instructie-video',
                    brand: 'goglam',
                    categories: ['All', 'Go Glam'],
                },
                {
                    type: 'commercial',
                    thumb: 'pottery_tvc_15.jpg',
                    video: `${cdnBase}videos/videosList/pottery_tvc_15.mp4`,
                    title: 'Pottery Studio',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'cell_phone_speaker_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/cell_phone_speaker_how_to_2017.mp4`,
                    title: 'Pottery Studio - Hoe maak je een speaker voor je mobiel en meer',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'jewelry_holder_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/jewelry_holder_how_to_2017.mp4`,
                    title: 'Pottery Studio - Hoe maak je een sieradenhouder',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'pottery_cool_tips_tricks_2017.jpg',
                    video: `${cdnBase}videos/videosList/pottery_cool_tips_tricks_2017.mp4`,
                    title: 'Pottery Studio - Tips en trucs',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'blingy_bank_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/blingy_bank_how_to_2017.mp4`,
                    title: 'Pottery Studio - Hoe maak je een spaarpot',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
            ],
        },
        nomatch: {
            title: 'Pagina niet gevonden',
        },
    },
};
