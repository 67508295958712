import {cdnBase} from '../../const';

export default {
    de_de: {
        langKeys: {
            en_us: 'English (US)',
            en_ca: 'English (CA)',
            en_gb: 'Großbritannien',
            en_au: 'Australien',
            fr_fr: 'Frankreich',
            nl_nl: 'Niederlande',
            de_de: 'Deutschland',
            it_it: 'Italien',
            cz_cz: 'Tschechien',
            hu_hu: 'Ungarn',
            pl_pl: 'Polen',
            ro_ro: 'Rumänien',
            sk_sk: 'Slowakei',
            mx_mx: 'Mexiko',
            es_es: 'Spanien',
            tr_tr: 'Türkei',
            hr_hr: 'Kroatien',
            ru_ru: 'Russland',
        },
        interstitial: {
            heading: 'Du bist gerade dabei, die Cool Maker Website zu verlassen.',
            copy: `Wenn du auf "Weiter" klickst, verlässt du diese Website und landest auf der Seite eines anderen Anbieters, für die andere Allgemeine Geschäftsbedingungen und Datenschutzrichtlinien gelten.`,
            continue: 'Weiter',
            cancel: 'Abbrechen',
        },
        ga: {
            copy: 'Wir verwenden auf dieser Seite Cookies, um technisch ein bestmögliches Erlebnis zu bieten. Indem du diese Seite besuchst, stimmst du der Verwendung von Cookies zu. Unsere Cookies Richtlinien erklären, was Cookies sind, wie wir und Unternehmen, mit denen wir zusammenarbeiten, sie verwenden und geben weiterführende Informationen zum Thema Cookies.',
            continue: 'Weiter',
            disable: 'Cookies deaktivieren',
        },
        brand: 'Cool Maker',
        applinks: {
            apple: 'LINK ZUR APPLE APP',
            google: 'LINK ZUR GOOGLE APP',
        },
        chooseRegion: 'Land auswählen',
        loading: 'Wird geladen',
        select: 'Auswählen',
        clearAll: 'Alle löschen',
        filters: 'Filter',
        details: 'Details',
        instructions: 'Anleitung herunterladen',
        videoInstructions: 'Videoanleitung',
        warnings: {
            enableIframes: 'Du musst iFrames aktivieren, um das Video anschauen zu können.',
        },
        helmet: {
            siteTitle: ' | Cool Maker ',
        },
        navigation: {
            youtube_link: 'https://www.youtube.com/c/CoolMaker',
            youtube_alt: 'YouTube Icon',
            instagram_link: 'https://www.instagram.com/creatorverseofficial/?hl=en',
            instagram_alt: 'Instagram Icon',
            fb_link: 'https://www.facebook.com/CreatorverseOfficial/',
            fb_alt: 'Facebook Icon',

            mainmenu: [
                {
                    type: 'internal',
                    href: '/',
                    display: 'Home',
                },
                // {
                //     type: 'internal',
                //     href: '/videos',
                //     display: 'Videos',
                // },
                {
                    type: 'internal',
                    href: '/toys',
                    display: 'Produkte',
                },
                {
                    type: 'internal_hash',
                    href: '#wheretobuy',
                    display: 'Wo erhältlich',
                },
                {
                    type: 'external',
                    href: 'https://spinmastersupport.helpshift.com/a/cool-maker/?p=web',
                    display: 'Hilfe',
                },
            ],
        },

        slides: [
            {
                class: 'half_split',
                bgClass: 'popStyle_gradient',
                heading: '',
                subheading:
                    "Im Handumdrehen stylische Kachelarmbänder herstellen und immer wieder neu machen! Keine Knoten, kein Schneiden, keine Verschlüsse - einfach Steinchen einhängen, austauschen - pop - und fertig!", 
                img: `${cdnBase}2023_summerUpdate/hero_popstyle_img.png`,
                alt: 'Cool Maker pop style toy image',
                logo: `${cdnBase}2023_summerUpdate/hero-popstyle-logo.png`,
                logo_alt: 'coolmaker pop style bracelet maker logo',
                ctas: [
                    {
                        cta_type: "internal",
                        cta_label: 'Mehr erfahren',
                        cta_link: '/toys/778988248829',
                    },
                    // {
                    //     cta_type: "video",
                    //     cta_label: 'Watch Now',
                    //     cta_link: `https://coolmaker2020.s3.amazonaws.com/videos/videosList/F22+Cool+Maker+Stitch+'n+Style+30sTVC-REVISED+SMCMS22301H+web.mp4`,
                    // },
                    {
                        cta_type: "external",
                        cta_label: "Anleitung",
                        cta_link: `https://coolmaker2020.s3.amazonaws.com/2023_summerUpdate/PopStyle_Instructions.pdf`
                    },
                ],
            },
        ],
        footer: {
            brand: 'Spin Master',
            contactUs: 'Kontakt aufnehmen',
            privacy: 'Datenschutzrichtlinie',
            terms: 'Allgemeine Geschäftsbedingungen',
            support: 'Hilfe',
            copy: 'Spin Master Ltd. Alle Rechte vorbehalten.',
            contactLink:
                'https://www.spinmaster.com/contact-us.php?userLoc=us&display=customer_service_info',
            privacyLink:
                'https://www.spinmaster.com/privacy-policy.php?userLoc=us',
            termsLink:
                'https://www.spinmaster.com/terms-of-service.php?userLoc=us',
            supportLink: 'https://www.spinmaster.com/support.php?userLoc=us',
        },
        home: {
            title: 'Home',
            whereToBuy: 'Wo erhältlich',
            productCaro: 'Kreativ werden!',
            viewAll: 'Alle anschauen',
            sections: {
                retailers: {
                    header: 'Wo erhältlich',
                    retailers: [
                        {
                            name: 'Smyths',
                            href: 'https://www.smythstoys.com/de/de-de/spielzeug/kreativit%c3%a4t-musik-und-party/cool-maker/c/cool-maker',
                            img: `${cdnBase}retailers/de/smyths.jpg`,
                        },
                        {
                            name: 'Amazon',
                            href: 'https://www.amazon.de/stores/CoolMaker/page/5235589B-EBCD-429D-8BCB-ECC7B021C11C?ref_=ast_bln',
                            img: `${cdnBase}retailers/de/amazon_de.jpg`,
                        },
                        {
                            name: 'Drogerie Müller',
                            href: 'https://www.mueller.de/search/?q=Cool+Maker&ms=true',
                            img: `${cdnBase}retailers/de/muller.jpg`,
                        },
                        {
                            name: 'Rofu',
                            href: 'https://www.rofu.de/suche/?searchPhrase=Cool%20Maker',
                            img: `${cdnBase}retailers/de/rofu.jpg`,
                        },
                        {
                            name: 'GALERIA',
                            href: 'https://www.galeria.de/suche?q=Cool%20Maker',
                            img: `${cdnBase}retailers/de/galeria.jpg`,
                        },


                    ],
                },
            },
            slides: [
                
                {
                    class: 'half_split',
                    heading: 'Bring their fashion dreams to life!',
                    subheading: `Just pop in the cartridge and watch it go. Smart stitch sensors know when to sew and stop. Then customize each creation. It's sewing made easy!`,
                    img: `${cdnBase}homepage/banners/hero-stitchNstyle.png`,
                    alt: `stitch 'n style logo`,
                    logo: `${cdnBase}homepage/banners/stitchNstyle-logo.png`,
                    logo_alt: 'scroll & stamp',
                    ctas: [
                        {
                            cta_label: 'Learn More',
                            cta_link: '/toys/778988412831',
                        },
                        {
                            cta_label: 'Templates',
                            cta_link: `https://coolmaker2020.s3.amazonaws.com/Assets+for+Dev+3/T37557_0008_20137993_GML_IS_Mainline.pdf`,
                        },
                    ],
                    learnMore: 'Learn More',
                    cta_link: '/toys/778988285367',
                },
                /*
        {
          class: "half_split",
          heading: 'Portable Nail Stamper',
          subheading: 'The removable stamper makes it so easy to stamp your nail art wherever you go!',
          img: `${cdnBase}homepage/banners/Carousel-2.png`,
          alt: "Portable Nail Stamper",
          logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
          logo_alt: "portable nail stamper",
          ctas: [
            {
              cta_label: "Learn More",
              cta_link: "/toys/778988285367"
            },
            {
              cta_label: "Watch Now",
              cta_link: "",
              cta_media: `${cdnBase}videos/banners/F21+CM+Go+Glam+U-Nique+Nail+Salon+30sDigiAdapt+FINAL.mp4`
            }
          ],
          learnMore: "Learn More",
          cta_link: "/toys/778988285367"
        },
        {
          class: "half_split",
          classImg: "add_push",
          heading: 'All-in-One Nail Kit',
          subheading: 'This salon has 5 design pods, nail polish, a dryer, storage, and a removable stamper!',
          img: `${cdnBase}homepage/banners/Carousel-3.png`,
          alt: "Custom Manicures",
          logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
          logo_alt: "go glam logo",
          ctas: [
            {
              cta_label: "Learn More",
              cta_link: "/toys/778988285367"
            },
            {
              cta_label: "Watch Now",
              cta_link: "",
              cta_media: `${cdnBase}videos/banners/F21+CM+Go+Glam+U-Nique+Nail+Salon+30sDigiAdapt+FINAL.mp4`
            }
          ],
          learnMore: "Learn More",
          cta_link: "/toys/778988285367"
        }*/
            ],
            promoBlocks: {
                hp_hero_video_block:{
                    class: "hero_videoblock",
                    hp_video: `${cdnBase}2023_summerUpdate/230721_CoolMaker_BraceletMaker_TVC15_Final+-+GERMANY.mp4`,
                    hp_video_img: `${cdnBase}2023_summerUpdate/popstyle-thumbnail-playbtn.png`,
                    hp_video_alt: 'popstyle video',
                },
                // hp_firstblock_single: {
                //     class: "stitchNstyle_section",
                //     title: 'Stich\'N Style Fashion studio',
                //     desc: 'Just pop in the cartridge and watch it go. Smart stitch sensors know when to sew and stop. Then customize each creation. It\'s sewing made easy! ',
                //     hp_video: `${cdnBase}videos/videosList/F22+Cool+Maker+Stitch+'n+Style+30sTVC-REVISED+SMCMS22301H+web.mp4`,
                //     hp_video_img: `${cdnBase}2023_summerUpdate/stitchNstyle-thumbnail.png`,
                //     hp_video_alt: 'StitchNstyle video',
                //     logo: `${cdnBase}homepage/banners/stitchNstyle-logo.png`,
                //     alt: 'stichNstyle logo',
                //     imgClass: 'imageclass-us',
                //     img: `${cdnBase}2023_summerUpdate/stitchNstyle-img.png`,
                //     ctas: [
                //         {
                //             label: 'Learn More',
                //             link: '778988419380',
                //         },
                //         {
                //             label: 'More Templates',
                //             link: `${cdnBase}downloads/2022_1208_templates.pdf`,
                //         },
                //     ],
                // },
                // hp_secondblock_single: {
                //     title: '2-in-1 KumiKreator',
                //     desc: 'Spin to create beautiful friendship bracelets and necklaces before your eyes with the Cool Maker KumiKreator Bead & Braider!',
                //     logo: `${cdnBase}homepage/banners/kumi-logo.png`,
                //     alt: 'Kumi Kreator logo',
                //     img: `${cdnBase}homepage/banners/kumi-kreator-img.png`,
                //     cta: 'Learn More',
                //     productId: '778988432426',
                // },
                // hp_thirdblock_double: [
                //     {
                //         title: 'Easy sculpt clay for kids!',
                //         img: `${cdnBase}homepage/banners/pottery-clay-img.png`,
                //         desc: 'Everything they need to make stylish clay creations like a cactus jewelry tree, jewelry bowl and tons more!',
                //         cta: 'Learn More',
                //         productId: '778988429723',
                //     },
                //     {
                //         title: 'Pottery made simple!',
                //         img: `${cdnBase}homepage/promoblocks/easy_pottery.jpg`,
                //         desc: 'Sculpt your way through endless fun with the Cool Maker Pottery Studio!',
                //         cta: 'Learn More',
                //         productId: '778988225790',
                //     },
                // ],
                // hp_fourthblock_single: {
                //     title: 'Head to our YouTube channel for more DIY inspiration!',
                //     desc: '',
                //     cta: "Let's Start Making!",
                //     cta_link: 'https://www.youtube.com/c/CoolMaker',
                //     img: `${cdnBase}homepage/banners/yt-img.png`,
                //     alt: 'Cool Maker Youtube images containing screenshots',
                // },
                // giphy_block: {
                //     class: 'giphy_promoblock',
                //     img: `${cdnBase}2023_summerUpdate/CM_GIPHY_MakesGonnaMake.gif`,
                //     alt: 'makers gonna make giphy',
                //     title: 'Show off your Maker pride on IG and TikTok!',
                //     cta: "Go to Giphy!",
                //     cta_link: 'https://giphy.com/search/cool-maker-stickers'

                // },
            },
        },
        collection: {
            title: 'Cool Maker Collection',
            nextPage: 'Next Page',
            prevPage: 'Prev Page',
            all: 'All',
            wantIt: 'Want it',
            haveIt: 'Have it',
            flipIt: 'Facts',
            scent: 'Scent',
            rarity: 'Rarity',
            color: 'Colour',
            showing: 'Showing',
            filters: ['scent', 'rarity', 'color'],
        },
        support: {
            title: 'Support',
            subTitle: 'Support',
        },
        toys: {
            title: 'Produkte',
            subTitle: 'Produkte',
            filterProd: 'Filter Products',
            learn: 'learn',
            more: 'more',
            goGlam: 'Go Glam',
            handcrafted: 'Handcrafted',
            jojo: 'Jo Jo',
            kumi: 'Kumi Kreator',
            pottery: 'Pottery',
            sewing: 'Sewing',
            tidydye: 'Tidy Dye',
            clearall: 'Clear All Filters',
        },
        toy: {
            title: 'Toy',
            subTitle: 'Toy',
            backToToys: 'Back to Toys',
            features: 'Besonderheiten',
            image: 'Image',
            contents: 'Inhalt',
            whereToBuy: 'Where to Buy',
            instructions: 'Instructions',
            video: 'Video',
            videoInstructions: 'Video Instructions',
            brandSite: 'brand site',
            buyNow: 'Buy Now',
            retailers: 'Retailers',
            coolMakerSalon: 'Cool Maker Nail Salon',
            bannerImg: `${cdnBase}2023_summerUpdate/product-banner.png`,
            bannerImg_mobile: `${cdnBase}2023_summerUpdate/product-banner.png`,
            bannerAlt: 'Toy Page Banner',
        },
        videos: {
            title: 'Videos',
            subTitle: 'Videos',
            bannerImg: `${cdnBase}videos/banners/VideoPage-DSKTP-Header_Fade.png`,
            bannerImg_mobile: `${cdnBase}videos/banners/VideoPage-MBL-Header-2.jpg`,
            bannerAlt: 'Video Page Banner',
            playVideo: 'Play Video',
            playButton_Alt: 'play button',
            downArrow_Alt: 'down arrow',
            defaultVideoIndex: 0,
            categories: 'Categories',
            catList: ['All', 'Commercials', 'Influencer'],
            prodList: [
                'All',
                // 'Sew Cool',
                'Popstyle Bracelet',
                'Pottery',
                // 'Go Glam',
                // 'Hollywood Hair',
                'Shimmer Me',
                'Stitch N Style',
                'KumiKreator'

            ],
            videoList: [
                {
                    type: `commercial`,
                    thumb: `popstyle-thumbnail.png`,
                    video: `${cdnBase}videos/videosList/CoolMaker_PopStyleBracelet_30E_WEB_HighRes.mp4`,
                    title: `Popstyle Bracelet`,
                    brand: `popstyle`,
                    categories: [`All`, `Popstyle Bracelet`],
                },
                {
                    type: `commercial`,
                    thumb: `F22_CoolMaker_StitchNStyle_DigitalAdapt_2_resize.jpg`,
                    video: `${cdnBase}videos/videosList/F22+Cool+Maker+Stitch+'n+Style+30sTVC-REVISED+SMCMS22301H+web.mp4`,
                    title: `Stitch N’ Style Fashion Studio`,
                    brand: `stich_n_style`,
                    categories: [`All`, `Stitch N Style`],
                },
                {
                    type: `howto`,
                    thumb: `F22_CoolMaker_StitchNStyle_HowTo_resize.jpg`,
                    video: `${cdnBase}videos/videosList/F22+Cool+Maker+Stitch+'n+Style+How-To+Web.mp4`,
                    title: `Stitch N Style How To`,
                    brand: `stich_n_style`,
                    categories: [`All`, `Stitch N Style`],
                },
                {
                    type: `howto`,
                    thumb: `F22_CoolMaker_KumiKreator_BeadNBraider_HowTo_resize.jpg`,
                    video: `${cdnBase}videos/videosList/F22+Cool+Maker+KumiKreator+Bead+'n+Braider+How-To+Web.mp4`,
                    title: `KumiKreator How To`,
                    brand: `kumi`,
                    categories: [`All`, `KumiKreator`],
                },
                // {
                //     type: `commercial`,
                //     thumb: `CoolMaker-HollywoodHair-TVC-Thumbnail-Videopage.jpg`,
                //     video: `${cdnBase}videos/videosList/F20+CoolMaker+HollywoodHair+30sTVC+FINAL+720p+noslate.mp4`,
                //     title: `Hollywood Hair Styled by You`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, `Hollywood Hair`],
                // },
                // {
                //     type: `tvc`,
                //     thumb: `CoolMaker-GoGlam-TVC-Thumbnail-Videopage.jpg`,
                //     video: `${cdnBase}videos/videosList/F20+CoolMaker+GoGlamDeluxe+30sTVC+FINAL+720p+noslate.mp4`,
                //     title: `Go Glam Instant Salon Style`,
                //     brand: `goglam`,
                //     categories: [`All`, 'Go Glam'],
                // },
                {
                    type: `howto`,
                    thumb: `F21_CoolMaker_HowTo_ShimmerMe.jpg`,
                    video: `${cdnBase}videos/videosList/CoolMaker-ShimmerMe-Kem-Final-H264-NoENDSLATE.mp4`,
                    title: `Shimmer Me How To`,
                    brand: `hollywoodhair`,
                    categories: [`All`, 'Shimmer Me'],
                },
                // {
                //     type: `howto`,
                //     thumb: `CoolMaker_HollywoodHair_Troubleshooting.jpg`,
                //     video: `${cdnBase}videos/videosList/HH_Troubleshooting_Final.mp4`,
                //     title: `Hollywood Hair Troubleshooting`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, 'Hollywood Hair'],
                // },
                // {
                //     type: `howto`,
                //     thumb: `CoolMaker_HollywoodHair_Starstruck.jpg`,
                //     video: `${cdnBase}videos/videosList/HH_StarStruckRefill_Final.mp4`,
                //     title: `Starstruck Refill Pack How-To`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, 'Hollywood Hair'],
                // },
                // {
                //     type: `howto`,
                //     thumb: `CoolMaker_HollywoodHair_PartyPop.jpg`,
                //     video: `${cdnBase}videos/videosList/HH_PartyPopRefill_Final.mp4`,
                //     title: `Party Pop Refill Pack How-To`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, 'Hollywood Hair'],
                // },
                // {
                //     type: `howto`,
                //     thumb: `CoolMaker_Template_Curls.jpg`,
                //     video: `${cdnBase}videos/videosList/HH_MakingCurls_Final.mp4`,
                //     title: `How-To Make Curls`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, 'Hollywood Hair'],
                // },
                // {
                //     type: `howto`,
                //     thumb: `CoolMaker_HollywoodHair_FULL.jpg`,
                //     video: `${cdnBase}videos/videosList/HH_HowTo_Final.mp4`,
                //     title: `Hollywood Hair How-To`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, 'Hollywood Hair'],
                // },
                // {
                //     type: `howto`,
                //     thumb: `CoolMaker_HollywoodHair_Clipping+Storage.jpg`,
                //     video: `${cdnBase}videos/videosList/HH_ClippingIntoHair-Storage_Final.mp4`,
                //     title: `Clipping into Hair and Storage`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, 'Hollywood Hair'],
                // },
                // {
                //     type: 'howto',
                //     thumb: 'kumi-how-to.jpg',
                //     video: `${cdnBase}videos/videosList/F19+CM+KumiKreator2-in-1+UpdatedPkg+How-To+FINAL+720p+noendslate.mp4`,
                //     title: 'KumiKreator How To',
                //     brand: 'goglam',
                //     categories: ['All', 'Go Glam'],
                // },
                // {
                //     type: 'howto',
                //     thumb: 'go-glam-how-to.jpg',
                //     video: `${cdnBase}videos/videosList/Cool+Maker_Go-Glam_Final_16x9_190809-_NO+ENDSLATE.mp4`,
                //     title: 'Go Glam How-To Video',
                //     brand: 'goglam',
                //     categories: ['All', 'Go Glam'],
                // },
                // {
                //     type: 'commercial',
                //     thumb: 'sew_tvc_15.jpg',
                //     video: `${cdnBase}videos/videosList/sew_tvc_15.mp4`,
                //     title: "Sew N' Style Machine",
                //     brand: 'sewing',
                //     categories: ['All', 'Sew Cool'],
                // },
                {
                    type: 'commercial',
                    thumb: 'pottery_tvc_15.jpg',
                    video: `${cdnBase}videos/videosList/pottery_tvc_15.mp4`,
                    title: 'Pottery Studio',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                // {
                //     type: 'howto',
                //     thumb: 'sew_n_style_how_to.jpg',
                //     video: `${cdnBase}videos/videosList/sew_n_style_how_to.mp4`,
                //     title: "Sew N' Style Machine",
                //     brand: 'sewing',
                //     categories: ['All', 'Pottery'],
                // },
                {
                    type: 'howto',
                    thumb: 'cell_phone_speaker_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/cell_phone_speaker_how_to_2017.mp4`,
                    title: 'Pottery Studio - How To Make A Cell Phone Speaker And More',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'jewelry_holder_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/jewelry_holder_how_to_2017.mp4`,
                    title: 'Pottery Studio - How To Make A Jewelry Holder',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'pottery_cool_tips_tricks_2017.jpg',
                    video: `${cdnBase}videos/videosList/pottery_cool_tips_tricks_2017.mp4`,
                    title: 'Pottery Studio - Tips and Tricks',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'blingy_bank_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/blingy_bank_how_to_2017.mp4`,
                    title: 'Pottery Studio - How To Make A Blinky Bank',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
            ],
        },
        nomatch: {
            title: 'Page Not Found',
        },
    },
};