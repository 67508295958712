import LocalizedStrings from 'react-localization'

/* eslint-disable camelcase */
import en_us from './en_us'
import en_au from './en_au'
import en_gb from './en_gb'
import en_ca from './en_ca'
import de_de from './de_de'
import es_es from './es_es'
import mx_mx from './mx_mx'
import it_it from './it_it'
import nl_nl from './nl_nl'
import pl_pl from './pl_pl'
import fr_fr from './fr_fr'
import sk_sk from './sk_sk'
import cz_cz from './cz_cz'
import hu_hu from './hu_hu'
import ro_ro from './ro_ro'
import ru_ru from './ru_ru'

export const lang = new LocalizedStrings({
  ...en_us,
  ...en_ca,
  ...en_au,
  ...en_gb,
  ...de_de,
  ...es_es,
  ...mx_mx,
  ...it_it,
  ...nl_nl,
  ...pl_pl,
  ...fr_fr,
  ...sk_sk,
  ...cz_cz,
  ...hu_hu,
  ...ro_ro,
  ...ru_ru,
})

