import {cdnBase} from '../../const';

export default {
    it_it: {
        langKeys: {
            en_us: 'Inglese (US)',
            en_ca: 'Inglese (CA)',
            en_gb: 'Inglese (UK)',
            en_au: 'Inglese (AU)',
            fr_fr: 'Francese',
            nl_nl: 'Olandese',
            de_de: 'Tedesco',
            it_it: 'Italiano',
            cz_cz: 'Ceco',
            hu_hu: 'Ungherese',
            pl_pl: 'Polacco',
            ro_ro: 'Romeno',
            sk_sk: 'Slovacco',
            mx_mx: 'Spagnolo (Messico)',
            es_es: 'Spagnolo (Spagna)',
            tr_tr: 'Turco',
            hr_hr: 'Croato',
            ru_ru: 'Russo',
        },
        interstitial: {
            heading: 'Stai lasciando il sito di Cool Maker',
            copy: `Cliccando "continua" lascerai questo sito e raggiungerai un sito terzo, sul quale sono applicate diverse policu in termini di privacy`,
            continue: 'Continua',
            cancel: 'Cancella',
        },
        ga: {
            copy: "Utilizziamo i cookies sul nostro sito per migliorare la vostra esperienza. Navigando su questo sito, acconsentite all'utilizzo dei cookies. La nostra Policy sui Cookie spiega cosa sono i cookies, come li utilizziamo, come parti terze con cui potremmo collaborare li utilizzano sui Servizi, le vostre scelte e altre informazioni riguardanti i cookies.",
            continue: 'Continua',
            disable: 'Disabilita Cookies',
        },
        brand: 'Cool Maker',
        applinks: {
            apple: "LINK ALL'APP APPLE",
            google: "LINK ALL'APP GOOGLE",
        },
        chooseRegion: 'Scegli una lingua',
        loading: 'Caricamento in corso',
        select: 'Seleziona',
        clearAll: 'Cancella tutto',
        filters: 'Filtri',
        details: 'Dettagli',
        instructions: 'Scarica Istruzioni',
        videoInstructions: 'Video Istruzioni',
        warnings: {
            enableIframes: 'È necessario abilitare iFrame per vedere questo video',
        },
        helmet: {
            siteTitle: ' | Cool Maker ',
        },
        navigation: {
            youtube_link: 'https://www.youtube.com/c/CoolMaker',
            youtube_alt: 'YouTube Icon',
            instagram_link: 'https://www.instagram.com/creatorverseofficial/?hl=en',
            instagram_alt: 'Instagram Icon',
            fb_link: 'https://www.facebook.com/CreatorverseOfficial/',
            fb_alt: 'Facebook Icon',

            mainmenu: [
                {
                    type: 'internal',
                    href: '/',
                    display: 'Home',
                },
                // {
                //     type: 'internal',
                //     href: '/videos',
                //     display: 'Videos',
                // },
                // {
                //     type: 'internal',
                //     href: '/toys',
                //     display: 'Prodotti',
                // },
                {
                    type: 'internal_hash',
                    href: '#wheretobuy',
                    display: 'Dove Acquistare',
                },
                {
                    type: 'external',
                    href: 'https://spinmastersupport.helpshift.com/a/cool-maker/?p=web',
                    display: 'Supporto',
                },
            ],
        },

        slides: [
            {
                class: 'half_split',
                bgClass: 'popStyle_gradient',
                heading: '',
                subheading:
                    "Crea e ricrea braccialetti alla moda con un solo click! Non sono necessari nodi, forbici o fermagli - basta premere sulla sulla Macchina Crea Braccialetti per un divertimento senza preoccupazioni!", 
                img: `${cdnBase}2023_summerUpdate/hero_popstyle_img.png`,
                alt: 'Cool Maker pop style toy image',
                logo: `${cdnBase}2023_summerUpdate/hero-popstyle-logo.png`,
                logo_alt: 'coolmaker pop style bracelet maker logo',
                ctas: [
                    {
                        cta_type: "internal",
                        cta_label: 'Scopri di più',
                        cta_link: '/toys/778988469019',
                    },
                    // {
                    //     cta_type: "video",
                    //     cta_label: 'Watch Now',
                    //     cta_link: `https://coolmaker2020.s3.amazonaws.com/videos/videosList/F22+Cool+Maker+Stitch+'n+Style+30sTVC-REVISED+SMCMS22301H+web.mp4`,
                    // },
                    {
                        cta_type: "external",
                        cta_label: "Istruzioni",
                        cta_link: `https://coolmaker2020.s3.amazonaws.com/2023_summerUpdate/PopStyle_Instructions.pdf`
                    },
                ],
            },
        ],
        footer: {
            brand: 'Spin Master',
            contactUs: 'Contattaci',
            privacy: 'Privacy Policy',
            terms: 'Termini di Servizio',
            support: 'Supporto',
            copy: 'Spin Master Ltd. All Rights Reserved.',
            contactLink:
                'https://www.spinmaster.com/contact-us.php?userLoc=us&display=customer_service_info',
            privacyLink:
                'https://www.spinmaster.com/privacy-policy.php?userLoc=us',
            termsLink:
                'https://www.spinmaster.com/terms-of-service.php?userLoc=us',
            supportLink: 'https://www.spinmaster.com/support.php?userLoc=us',
        },
        home: {
            title: 'Home',
            whereToBuy: 'Dove Acquistare',
            productCaro: 'Inizia a Creare! ',
            viewAll: 'Visualizza tutti',
            sections: {
                retailers: {
                    header: 'Dove Acquistare',
                    retailers: [
                        {
                            name: 'Toys Center',
                            href: 'https://www.toyscenter.it/prodotto/cool-maker-pop-style-bracelet-maker-macchina-crea-braccialetti-con-170-perline-alla-moda-e-20-elastici-per-bambine-e-bambini-7-anni/',
                            img: `${cdnBase}retailers/it/toyscenter.jpg`,
                        },
                        {
                            name: 'Amazon',
                            href: 'https://www.amazon.it/Cool-Maker-Pop-Style-Bracelet/dp/B0BJ2G1ZD5/ref=sr_1_3?__mk_it_IT=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3G7WC7JCY1PFY&keywords=macchina+crea+braccialetti+cool+maker&qid=1690991319&sprefix=macchina+crea+braccialetti+cool+maker%2Caps%2C111&sr=8-3',
                            img: `${cdnBase}retailers/it/amazon_it.jpg`,
                        },
                        {
                            name: 'Giodicart',
                            href: 'https://giodicart.it/articolo/X41753?utm_source=gf&utm_medium=x&utm_campaign=gf&gclid=CjwKCAjw_aemBhBLEiwAT98FMnToTqqIkJVDQQJvnz528d9fcAgo2O7jKyYrsrwZ6XSEPh5Eh4zkFxoCBhMQAvD_BwE',
                            img: `${cdnBase}retailers/it/giodicart.jpg`,
                        },
                        {
                            name: 'Mornati Paglia',
                            href: 'https://www.mornatipaglia.com/products/spin-master-cool-maker-macchina-crea-braccialetti-pop-style-6067289',
                            img: `${cdnBase}retailers/a2d6fc2b-707f-4594-98e2-0c7e083be0e4.jpeg`,
                        },
                        // {
                        //     name: 'Giocheria',
                        //     href: 'https://giocheria.it/',
                        //     img: `${cdnBase}retailers/it/giocheria.jpg`,
                        // },
                        {
                            name: 'Il Capriccio Giocattoli',
                            href: 'https://www.ilcapricciostore.com/it/cool-maker-goglam-macchina-crea-braccialetti-6067289',
                            img: `${cdnBase}retailers/it/Il-Capriccio-Giocattoli.jpg`,
                        },
                    ],
                },
            },
            slides: [
                
                {
                    class: 'half_split',
                    heading: 'Bring their fashion dreams to life!',
                    subheading: `Just pop in the cartridge and watch it go. Smart stitch sensors know when to sew and stop. Then customize each creation. It's sewing made easy!`,
                    img: `${cdnBase}homepage/banners/hero-stitchNstyle.png`,
                    alt: `stitch 'n style logo`,
                    logo: `${cdnBase}homepage/banners/stitchNstyle-logo.png`,
                    logo_alt: 'scroll & stamp',
                    ctas: [
                        {
                            cta_label: 'Learn More',
                            cta_link: '/toys/778988412831',
                        },
                        {
                            cta_label: 'Templates',
                            cta_link: `https://coolmaker2020.s3.amazonaws.com/Assets+for+Dev+3/T37557_0008_20137993_GML_IS_Mainline.pdf`,
                        },
                    ],
                    learnMore: 'Learn More',
                    cta_link: '/toys/778988285367',
                },
                /*
        {
          class: "half_split",
          heading: 'Portable Nail Stamper',
          subheading: 'The removable stamper makes it so easy to stamp your nail art wherever you go!',
          img: `${cdnBase}homepage/banners/Carousel-2.png`,
          alt: "Portable Nail Stamper",
          logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
          logo_alt: "portable nail stamper",
          ctas: [
            {
              cta_label: "Learn More",
              cta_link: "/toys/778988285367"
            },
            {
              cta_label: "Watch Now",
              cta_link: "",
              cta_media: `${cdnBase}videos/banners/F21+CM+Go+Glam+U-Nique+Nail+Salon+30sDigiAdapt+FINAL.mp4`
            }
          ],
          learnMore: "Learn More",
          cta_link: "/toys/778988285367"
        },
        {
          class: "half_split",
          classImg: "add_push",
          heading: 'All-in-One Nail Kit',
          subheading: 'This salon has 5 design pods, nail polish, a dryer, storage, and a removable stamper!',
          img: `${cdnBase}homepage/banners/Carousel-3.png`,
          alt: "Custom Manicures",
          logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
          logo_alt: "go glam logo",
          ctas: [
            {
              cta_label: "Learn More",
              cta_link: "/toys/778988285367"
            },
            {
              cta_label: "Watch Now",
              cta_link: "",
              cta_media: `${cdnBase}videos/banners/F21+CM+Go+Glam+U-Nique+Nail+Salon+30sDigiAdapt+FINAL.mp4`
            }
          ],
          learnMore: "Learn More",
          cta_link: "/toys/778988285367"
        }*/
            ],
            promoBlocks: {
                hp_hero_video_block:{
                    class: "hero_videoblock",
                    hp_video: `${cdnBase}Assets+for+Dev+3/COOL_MAKER_MACCHINA_DEI_BRACCIALETTI_15sec_WEB.mp4`,
                    hp_video_img: `${cdnBase}2023_summerUpdate/popstyle-thumbnail-playbtn.png`,
                    hp_video_alt: 'popstyle video',
                },
                // hp_firstblock_single: {
                //     class: "stitchNstyle_section",
                //     title: 'Stich\'N Style Fashion studio',
                //     desc: 'Just pop in the cartridge and watch it go. Smart stitch sensors know when to sew and stop. Then customize each creation. It\'s sewing made easy! ',
                //     hp_video: `${cdnBase}videos/videosList/F22+Cool+Maker+Stitch+'n+Style+30sTVC-REVISED+SMCMS22301H+web.mp4`,
                //     hp_video_img: `${cdnBase}2023_summerUpdate/stitchNstyle-thumbnail.png`,
                //     hp_video_alt: 'StitchNstyle video',
                //     logo: `${cdnBase}homepage/banners/stitchNstyle-logo.png`,
                //     alt: 'stichNstyle logo',
                //     imgClass: 'imageclass-us',
                //     img: `${cdnBase}2023_summerUpdate/stitchNstyle-img.png`,
                //     ctas: [
                //         {
                //             label: 'Learn More',
                //             link: '778988419380',
                //         },
                //         {
                //             label: 'More Templates',
                //             link: `${cdnBase}downloads/2022_1208_templates.pdf`,
                //         },
                //     ],
                // },
                // hp_secondblock_single: {
                //     title: '2-in-1 KumiKreator',
                //     desc: 'Spin to create beautiful friendship bracelets and necklaces before your eyes with the Cool Maker KumiKreator Bead & Braider!',
                //     logo: `${cdnBase}homepage/banners/kumi-logo.png`,
                //     alt: 'Kumi Kreator logo',
                //     img: `${cdnBase}homepage/banners/kumi-kreator-img.png`,
                //     cta: 'Learn More',
                //     productId: '778988432426',
                // },
                // hp_thirdblock_double: [
                //     {
                //         title: 'Easy sculpt clay for kids!',
                //         img: `${cdnBase}homepage/banners/pottery-clay-img.png`,
                //         desc: 'Everything they need to make stylish clay creations like a cactus jewelry tree, jewelry bowl and tons more!',
                //         cta: 'Learn More',
                //         productId: '778988429723',
                //     },
                //     {
                //         title: 'Pottery made simple!',
                //         img: `${cdnBase}homepage/promoblocks/easy_pottery.jpg`,
                //         desc: 'Sculpt your way through endless fun with the Cool Maker Pottery Studio!',
                //         cta: 'Learn More',
                //         productId: '778988225790',
                //     },
                // ],
                // hp_fourthblock_single: {
                //     title: 'Head to our YouTube channel for more DIY inspiration!',
                //     desc: '',
                //     cta: "Let's Start Making!",
                //     cta_link: 'https://www.youtube.com/c/CoolMaker',
                //     img: `${cdnBase}homepage/banners/yt-img.png`,
                //     alt: 'Cool Maker Youtube images containing screenshots',
                // },
                // giphy_block: {
                //     class: 'giphy_promoblock',
                //     img: `${cdnBase}2023_summerUpdate/CM_GIPHY_MakesGonnaMake.gif`,
                //     alt: 'makers gonna make giphy',
                //     title: 'Show off your Maker pride on IG and TikTok!',
                //     cta: "Go to Giphy!",
                //     cta_link: 'https://giphy.com/search/cool-maker-stickers'

                // },
            },
        },
        collection: {
            title: 'Cool Maker Collection',
            nextPage: 'Next Page',
            prevPage: 'Prev Page',
            all: 'All',
            wantIt: 'Want it',
            haveIt: 'Have it',
            flipIt: 'Facts',
            scent: 'Scent',
            rarity: 'Rarity',
            color: 'Colour',
            showing: 'Showing',
            filters: ['scent', 'rarity', 'color'],
        },
        support: {
            title: 'Support',
            subTitle: 'Support',
        },
        toys: {
            title: 'Products',
            subTitle: 'Products',
            filterProd: 'Filter Products',
            learn: 'learn',
            more: 'more',
            goGlam: 'Go Glam',
            handcrafted: 'Handcrafted',
            jojo: 'Jo Jo',
            kumi: 'Kumi Kreator',
            pottery: 'Pottery',
            sewing: 'Sewing',
            tidydye: 'Tidy Dye',
            clearall: 'Clear All Filters',
        },
        toy: {
            title: 'Toy',
            subTitle: 'Toy',
            backToToys: 'Back to Toys',
            features: 'Features',
            image: 'Image',
            contents: 'Contents',
            whereToBuy: 'Where to Buy',
            instructions: 'Instructions',
            video: 'Video',
            videoInstructions: 'Video Instructions',
            brandSite: 'brand site',
            buyNow: 'Buy Now',
            retailers: 'Retailers',
            coolMakerSalon: 'Cool Maker Nail Salon',
            bannerImg: `${cdnBase}2023_summerUpdate/product-banner.png`,
            bannerImg_mobile: `${cdnBase}2023_summerUpdate/product-banner.png`,
            bannerAlt: 'Toy Page Banner',
        },
        videos: {
            title: 'Videos',
            subTitle: 'Videos',
            bannerImg: `${cdnBase}videos/banners/VideoPage-DSKTP-Header_Fade.png`,
            bannerImg_mobile: `${cdnBase}videos/banners/VideoPage-MBL-Header-2.jpg`,
            bannerAlt: 'Video Page Banner',
            playVideo: 'Play Video',
            playButton_Alt: 'play button',
            downArrow_Alt: 'down arrow',
            defaultVideoIndex: 0,
            categories: 'Categories',
            catList: ['All', 'Commercials', 'Influencer'],
            prodList: [
                'All',
                // 'Sew Cool',
                'Popstyle Bracelet',
                'Pottery',
                // 'Go Glam',
                // 'Hollywood Hair',
                'Shimmer Me',
                'Stitch N Style',
                'KumiKreator'

            ],
            videoList: [
                {
                    type: `commercial`,
                    thumb: `popstyle-thumbnail.png`,
                    video: `${cdnBase}videos/videosList/CoolMaker_PopStyleBracelet_30E_WEB_HighRes.mp4`,
                    title: `Popstyle Bracelet`,
                    brand: `popstyle`,
                    categories: [`All`, `Popstyle Bracelet`],
                },
                {
                    type: `commercial`,
                    thumb: `F22_CoolMaker_StitchNStyle_DigitalAdapt_2_resize.jpg`,
                    video: `${cdnBase}videos/videosList/F22+Cool+Maker+Stitch+'n+Style+30sTVC-REVISED+SMCMS22301H+web.mp4`,
                    title: `Stitch N’ Style Fashion Studio`,
                    brand: `stich_n_style`,
                    categories: [`All`, `Stitch N Style`],
                },
                {
                    type: `howto`,
                    thumb: `F22_CoolMaker_StitchNStyle_HowTo_resize.jpg`,
                    video: `${cdnBase}videos/videosList/F22+Cool+Maker+Stitch+'n+Style+How-To+Web.mp4`,
                    title: `Stitch N Style How To`,
                    brand: `stich_n_style`,
                    categories: [`All`, `Stitch N Style`],
                },
                {
                    type: `howto`,
                    thumb: `F22_CoolMaker_KumiKreator_BeadNBraider_HowTo_resize.jpg`,
                    video: `${cdnBase}videos/videosList/F22+Cool+Maker+KumiKreator+Bead+'n+Braider+How-To+Web.mp4`,
                    title: `KumiKreator How To`,
                    brand: `kumi`,
                    categories: [`All`, `KumiKreator`],
                },
                // {
                //     type: `commercial`,
                //     thumb: `CoolMaker-HollywoodHair-TVC-Thumbnail-Videopage.jpg`,
                //     video: `${cdnBase}videos/videosList/F20+CoolMaker+HollywoodHair+30sTVC+FINAL+720p+noslate.mp4`,
                //     title: `Hollywood Hair Styled by You`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, `Hollywood Hair`],
                // },
                // {
                //     type: `tvc`,
                //     thumb: `CoolMaker-GoGlam-TVC-Thumbnail-Videopage.jpg`,
                //     video: `${cdnBase}videos/videosList/F20+CoolMaker+GoGlamDeluxe+30sTVC+FINAL+720p+noslate.mp4`,
                //     title: `Go Glam Instant Salon Style`,
                //     brand: `goglam`,
                //     categories: [`All`, 'Go Glam'],
                // },
                {
                    type: `howto`,
                    thumb: `F21_CoolMaker_HowTo_ShimmerMe.jpg`,
                    video: `${cdnBase}videos/videosList/CoolMaker-ShimmerMe-Kem-Final-H264-NoENDSLATE.mp4`,
                    title: `Shimmer Me How To`,
                    brand: `hollywoodhair`,
                    categories: [`All`, 'Shimmer Me'],
                },
                // {
                //     type: `howto`,
                //     thumb: `CoolMaker_HollywoodHair_Troubleshooting.jpg`,
                //     video: `${cdnBase}videos/videosList/HH_Troubleshooting_Final.mp4`,
                //     title: `Hollywood Hair Troubleshooting`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, 'Hollywood Hair'],
                // },
                // {
                //     type: `howto`,
                //     thumb: `CoolMaker_HollywoodHair_Starstruck.jpg`,
                //     video: `${cdnBase}videos/videosList/HH_StarStruckRefill_Final.mp4`,
                //     title: `Starstruck Refill Pack How-To`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, 'Hollywood Hair'],
                // },
                // {
                //     type: `howto`,
                //     thumb: `CoolMaker_HollywoodHair_PartyPop.jpg`,
                //     video: `${cdnBase}videos/videosList/HH_PartyPopRefill_Final.mp4`,
                //     title: `Party Pop Refill Pack How-To`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, 'Hollywood Hair'],
                // },
                // {
                //     type: `howto`,
                //     thumb: `CoolMaker_Template_Curls.jpg`,
                //     video: `${cdnBase}videos/videosList/HH_MakingCurls_Final.mp4`,
                //     title: `How-To Make Curls`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, 'Hollywood Hair'],
                // },
                // {
                //     type: `howto`,
                //     thumb: `CoolMaker_HollywoodHair_FULL.jpg`,
                //     video: `${cdnBase}videos/videosList/HH_HowTo_Final.mp4`,
                //     title: `Hollywood Hair How-To`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, 'Hollywood Hair'],
                // },
                // {
                //     type: `howto`,
                //     thumb: `CoolMaker_HollywoodHair_Clipping+Storage.jpg`,
                //     video: `${cdnBase}videos/videosList/HH_ClippingIntoHair-Storage_Final.mp4`,
                //     title: `Clipping into Hair and Storage`,
                //     brand: `hollywoodhair`,
                //     categories: [`All`, 'Hollywood Hair'],
                // },
                // {
                //     type: 'howto',
                //     thumb: 'kumi-how-to.jpg',
                //     video: `${cdnBase}videos/videosList/F19+CM+KumiKreator2-in-1+UpdatedPkg+How-To+FINAL+720p+noendslate.mp4`,
                //     title: 'KumiKreator How To',
                //     brand: 'goglam',
                //     categories: ['All', 'Go Glam'],
                // },
                // {
                //     type: 'howto',
                //     thumb: 'go-glam-how-to.jpg',
                //     video: `${cdnBase}videos/videosList/Cool+Maker_Go-Glam_Final_16x9_190809-_NO+ENDSLATE.mp4`,
                //     title: 'Go Glam How-To Video',
                //     brand: 'goglam',
                //     categories: ['All', 'Go Glam'],
                // },
                // {
                //     type: 'commercial',
                //     thumb: 'sew_tvc_15.jpg',
                //     video: `${cdnBase}videos/videosList/sew_tvc_15.mp4`,
                //     title: "Sew N' Style Machine",
                //     brand: 'sewing',
                //     categories: ['All', 'Sew Cool'],
                // },
                {
                    type: 'commercial',
                    thumb: 'pottery_tvc_15.jpg',
                    video: `${cdnBase}videos/videosList/pottery_tvc_15.mp4`,
                    title: 'Pottery Studio',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                // {
                //     type: 'howto',
                //     thumb: 'sew_n_style_how_to.jpg',
                //     video: `${cdnBase}videos/videosList/sew_n_style_how_to.mp4`,
                //     title: "Sew N' Style Machine",
                //     brand: 'sewing',
                //     categories: ['All', 'Pottery'],
                // },
                {
                    type: 'howto',
                    thumb: 'cell_phone_speaker_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/cell_phone_speaker_how_to_2017.mp4`,
                    title: 'Pottery Studio - How To Make A Cell Phone Speaker And More',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'jewelry_holder_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/jewelry_holder_how_to_2017.mp4`,
                    title: 'Pottery Studio - How To Make A Jewelry Holder',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'pottery_cool_tips_tricks_2017.jpg',
                    video: `${cdnBase}videos/videosList/pottery_cool_tips_tricks_2017.mp4`,
                    title: 'Pottery Studio - Tips and Tricks',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'blingy_bank_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/blingy_bank_how_to_2017.mp4`,
                    title: 'Pottery Studio - How To Make A Blinky Bank',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
            ],
        },
        nomatch: {
            title: 'Page Not Found',
        },
    },
};