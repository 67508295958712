import {cdnBase} from '../../const';

export default {
    cz_cz: {
        langKeys: {
            en_us: 'English (US)',
            en_ca: 'English (CA)',
            en_gb: 'English (UK)',
            en_au: 'English (AU)',
            fr_fr: 'France',
            nl_nl: 'Netherlands',
            de_de: 'Germany',
            it_it: 'Italy',
            cz_cz: 'Czech Republic',
            hu_hu: 'Hungary',
            pl_pl: 'Poland',
            ro_ro: 'Romania',
            sk_sk: 'Slovakia',
            mx_mx: 'Mexico',
            es_es: 'Spain',
            tr_tr: 'Turkey',
            hr_hr: 'Croatia',
            ru_ru: 'Russia',
        },
        interstitial: {
            heading: 'You are leaving the Cool Maker Website.',
            copy: `By clicking "continue" you'll be leaving this web site and landing on a third-party site, which is governed by different terms and privacy policies.`,
            continue: 'Continue',
            cancel: 'Cancel',
        },
        ga: {
            copy: 'We use cookies on this site to help improve your experience. By using this site, you consent to the use of cookies. Our Cookies Policy explains what cookies are, how we use cookies, how third­parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.',
            continue: 'Continue',
            disable: 'Disable Cookies',
        },
        brand: 'Cool Maker',
        applinks: {
            apple: 'LINK TO APPLE APP',
            google: 'LINK TO GOOGLE APP',
        },
        chooseRegion: 'Choose Language',
        loading: 'Loading',
        select: 'Select',
        clearAll: 'Clear All',
        filters: 'Filters',
        details: 'Details',
        instructions: 'download Instructions',
        videoInstructions: 'video Instructions',
        warnings: {
            enableIframes: 'You Must Enable iFrames to view this video',
        },
        helmet: {
            siteTitle: ' | Cool Maker ',
        },
        navigation: {
            youtube_link: 'https://www.youtube.com/c/CoolMaker',
            youtube_alt: 'YouTube Icon',
            instagram_link: 'https://www.instagram.com/coolmaker/',
            instagram_alt: 'Instagram Icon',
            fb_link: 'https://www.facebook.com/CoolMakerOfficial',
            fb_alt: 'Facebook Icon',

            mainmenu: [
                {
                    type: 'internal',
                    href: '/',
                    display: 'Home',
                },
                {
                    type: 'internal',
                    href: '/videos',
                    display: 'Videos',
                },
                {
                    type: 'internal',
                    href: '/toys',
                    display: 'Products',
                },
                {
                    type: 'internal_hash',
                    href: '#wheretobuy',
                    display: 'Where to Buy',
                },
                {
                    type: 'external',
                    href: 'https://spinmastersupport.helpshift.com/a/cool-maker/?p=web',
                    display: 'Support',
                },
            ],
        },
        meta: {
            title: 'Dream it. Create it. Unleash your creativity and become a Cool Maker!',
            tags: 'coolmaker,maker, sewing kits for kids, arts and crafts, kids toys, toys for girls, toy, cool maker, cool maker refill, go glam nail stamper, go glam nail stamper refill, go glam, 2 in 1 kumikreator, kumikreator bracelet maker, kumikreator refill, kumikreator, bracelet maker, friendship bracelet kit, nail stamper, jewelry making kit, toys for girls, sewing machine for kids',
        },
        footer: {
            brand: 'Spin Master',
            contactUs: 'Contact Us',
            privacy: 'Privacy Policy',
            terms: 'Terms of Service',
            support: 'Support',
            copy: 'Spin Master Ltd. All Rights Reserved.',
            contactLink:
                'https://www.spinmaster.com/contact-us.php?userLoc=us&display=customer_service_info',
            privacyLink:
                'https://www.spinmaster.com/privacy-policy.php?userLoc=us',
            termsLink:
                'https://www.spinmaster.com/terms-of-service.php?userLoc=us',
            supportLink: 'https://www.spinmaster.com/support.php?userLoc=us',
        },
        home: {
            title: 'Home',
            whereToBuy: 'Where To Buy',
            productCaro: 'Start Making ',
            viewAll: ' View All',
            sections: {
                retailers: {
                    header: 'Where to Buy',
                    retailers: [
                        {
                            name: 'amazon',
                            href: 'https://www.amazon.com/stores/node/17890291011?_encoding=UTF8&field-lbr_brands_browse-bin=Cool%20Maker&ref_=bl_dp_s_web_17890291011',
                            img: `${cdnBase}retailers/amazon.jpg`,
                        },
                        {
                            name: 'kmart',
                            href: 'https://www.kmart.com/',
                            img: `${cdnBase}retailers/kmart.jpg`,
                        },
                        {
                            name: 'meijer',
                            href: 'https://www.meijer.com/',
                            img: `${cdnBase}retailers/meijer.jpg`,
                        },
                        {
                            name: 'target',
                            href: 'https://www.target.com/s?searchTerm=cool+maker&category=0%7CAll%7Cmatchallpartial%7Call+categories&tref=typeahead%7Cterm%7C0%7Ccool+maker%7C%7C%7Cservice',
                            img: `${cdnBase}retailers/target.jpg`,
                        },
                        {
                            name: 'walmart',
                            href: 'https://www.walmart.com/search/?query=cool%20maker&cat_id=0',
                            img: `${cdnBase}retailers/walmart.jpg`,
                        },
                    ],
                },
            },
            slides: [
                {
                    class: 'half_split',
                    heading: 'Mix and match manicures!',
                    subheading:
                        'So many cute and trendy designs to match their own personal style!',
                    img: `${cdnBase}homepage/banners/Homepage-Header-Rotator3.png`,
                    alt: 'go glam girl',
                    logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
                    logo_alt: 'go glam logo',
                    ctas: [
                        {
                            cta_type: "internal",
                            cta_label: 'Learn More',
                            cta_link: '/toys/778988285367',
                        },
                    ],
                },
                {
                    class: 'half_split',
                    heading: 'Bring the salon home!',
                    subheading:
                        'Go Glam Nail Salon makes it easy to stamp designs perfectly on to nails.',
                    img: `${cdnBase}homepage/banners/Homepage-Header-Rotator1_resize.png`,
                    alt: 'Go Glam Nail Stamper',
                    logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
                    logo_alt: 'go glam logo',
                    ctas: [
                        {
                            cta_type: "internal",
                            cta_label: 'Learn More',
                            cta_link: '/toys/778988285367',
                        },
                    ],
                },
                {
                    class: 'half_split',
                    classImg: 'add_push',
                    heading: 'Custom manicures in minutes!',
                    subheading: 'Simply pop, paint and press.',
                    img: `${cdnBase}homepage/banners/Homepage-Header-Rotator2_resize.png`,
                    alt: 'Custom Manicures',
                    logo: `${cdnBase}homepage/banners/GoGlam-Logo.png`,
                    logo_alt: 'go glam logo',
                    ctas: [
                        {
                            cta_type: "internal",
                            cta_label: 'Learn More',
                            cta_link: '/toys/778988285367',
                        },
                    ],
                },
            ],
            promoBlocks: {
                hp_firstblock_single: {
                    class: "hollywoodHair_Section",
                    title: 'Hollywood Hair Extension Maker!',
                    desc: 'Design and create hair extensions with the Cool Maker Hollywood Hair Extension Maker!',
                    hp_video: `${cdnBase}videos/videosList/CoolMaker_GoGlam.mp4`,
                    hp_video_img: `${cdnBase}homepage/promoblocks/video_tvc.jpg`,
                    hp_video_alt: 'Hollywood hair video',
                    logo: `${cdnBase}homepage/promoblocks/HollywoodHair-Logo.png`,
                    alt: 'Hollywood Hair logo',
                    imgClass: 'imageclass',
                    img: `${cdnBase}homepage/promoblocks/HollywoodHair-Image.png`,
                    ctas: [
                        {
                            label: 'Learn More',
                            link: '778988302798',
                        },
                    ],
                },
                hp_secondblock_single: {
                    title: '2-in-1 KumiKreator',
                    desc: 'Spin to create beautiful friendship bracelets and necklaces before your eyes with the Cool Maker 2-in-1 KumiKreator!',
                    logo: `${cdnBase}homepage/promoblocks/KumiKreator-Logo.png`,
                    alt: 'Kumi Kreator logo',
                    img: `${cdnBase}homepage/promoblocks/KumiKreator-Image.png`,
                    cta: 'Learn More',
                    productId: '778988275191',
                },
                hp_thirdblock_double: [
                    {
                        title: 'Sew without thread!',
                        img: `${cdnBase}homepage/promoblocks/sew_wo_thread.jpg`,
                        desc: "Magically create amazing projects with the Cool Maker Sew N' Style Machine Kit!",
                        cta: 'Learn More',
                        productId: '778988311424',
                    },
                    {
                        title: 'Pottery made easy!',
                        img: `${cdnBase}homepage/promoblocks/easy_pottery.jpg`,
                        desc: 'Easily sculpt clay creations with the Cool Maker Pottery Studio!',
                        cta: 'Learn More',
                        productId: '778988225813',
                    },
                ],
                hp_fourthblock_single: {
                    title: 'Head to our YouTube channel for more DIY inspiration!',
                    desc: '',
                    cta: "Let's Start Making",
                    cta_link: 'https://www.youtube.com/c/CoolMaker',
                    img: `${cdnBase}homepage/promoblocks/YouTubeImage.png`,
                    alt: 'Cool Maker Youtube images containing screenshots',
                },
            },
        },
        collection: {
            title: 'Cool Maker Collection',
            nextPage: 'Next Page',
            prevPage: 'Prev Page',
            all: 'All',
            wantIt: 'Want it',
            haveIt: 'Have it',
            flipIt: 'Facts',
            scent: 'Scent',
            rarity: 'Rarity',
            color: 'Color',
            showing: 'Showing',
            filters: ['scent', 'rarity', 'color'],
        },
        support: {
            title: 'Support',
            subTitle: 'Support',
        },
        toys: {
            title: 'Products',
            subTitle: 'Products',
            filterProd: 'Filter Products',
            learn: 'learn',
            more: 'more',
            goGlam: 'Go Glam',
            handcrafted: 'Handcrafted',
            jojo: 'Jo Jo',
            kumi: 'Kumi Kreator',
            pottery: 'Pottery',
            sewing: 'Sewing',
            tidydye: 'Tidy Dye',
            clearall: 'Clear All Filters',
        },
        toy: {
            title: 'Toy',
            subTitle: 'Toy',
            backToToys: 'Back to Toys',
            features: 'Features',
            image: 'Image',
            contents: 'Contents',
            whereToBuy: 'Where to Buy',
            instructions: 'Instructions',
            video: 'Video',
            videoInstructions: 'Video Instructions',
            brandSite: 'brand site',
            buyNow: 'Buy Now',
            retailers: 'Retailers',
            coolMakerSalon: 'Cool Maker Nail Salon',
            bannerImg: `${cdnBase}toy/ProductPage-DSKTP-Header_Fade.png`,
            bannerImg_mobile: `${cdnBase}toy/ProductPage-MBL-Header-2.jpg`,
            bannerAlt: 'Toy Page Banner',
        },
        videos: {
            title: 'Videos',
            subTitle: 'Videos',
            bannerImg: `${cdnBase}videos/banners/VideoPage-DSKTP-Header_Fade.png`,
            bannerImg_mobile: `${cdnBase}videos/banners/VideoPage-MBL-Header-2.jpg`,
            bannerAlt: 'Video Page Banner',
            playVideo: 'Play Video',
            playButton_Alt: 'play button',
            downArrow_Alt: 'down arrow',
            defaultVideoIndex: 0,
            categories: 'Categories',
            catList: ['All', 'Commercials', 'Influencer'],
            prodList: ['All', 'Sew Cool', 'Pottery', 'Go Glam'],
            videoList: [
                {
                    type: 'howto',
                    thumb: 'kumi-how-to.jpg',
                    video: `${cdnBase}videos/videosList/F19+CM+KumiKreator2-in-1+UpdatedPkg+How-To+FINAL+720p+noendslate.mp4`,
                    title: 'KumiKreator How To',
                    brand: 'goglam',
                    categories: ['All', 'Go Glam'],
                },
                {
                    type: 'howto',
                    thumb: 'go-glam-how-to.jpg',
                    video: `${cdnBase}videos/videosList/Cool+Maker_Go-Glam_Final_16x9_190809-_NO+ENDSLATE.mp4`,
                    title: 'Go Glam How-To Video',
                    brand: 'goglam',
                    categories: ['All', 'Go Glam'],
                },
                {
                    type: 'commercial',
                    thumb: 'sew_tvc_15.jpg',
                    video: `${cdnBase}videos/videosList/sew_tvc_15.mp4`,
                    title: "Sew N' Style Machine",
                    brand: 'sewing',
                    categories: ['All', 'Sew Cool'],
                },
                {
                    type: 'commercial',
                    thumb: 'pottery_tvc_15.jpg',
                    video: `${cdnBase}videos/videosList/pottery_tvc_15.mp4`,
                    title: 'Pottery Studio',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'sew_n_style_how_to.jpg',
                    video: `${cdnBase}videos/videosList/sew_n_style_how_to.mp4`,
                    title: "Sew N' Style Machine",
                    brand: 'sewing',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'cell_phone_speaker_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/cell_phone_speaker_how_to_2017.mp4`,
                    title: 'Pottery Studio - How To Make A Cell Phone Speaker And More',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'jewelry_holder_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/jewelry_holder_how_to_2017.mp4`,
                    title: 'Pottery Studio - How To Make A Jewelry Holder',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'pottery_cool_tips_tricks_2017.jpg',
                    video: `${cdnBase}videos/videosList/pottery_cool_tips_tricks_2017.mp4`,
                    title: 'Pottery Studio - Tips and Tricks',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
                {
                    type: 'howto',
                    thumb: 'blingy_bank_how_to_2017.jpg',
                    video: `${cdnBase}videos/videosList/blingy_bank_how_to_2017.mp4`,
                    title: 'Pottery Studio - How To Make A Blinky Bank',
                    brand: 'pottery',
                    categories: ['All', 'Pottery'],
                },
            ],
        },
        nomatch: {
            title: 'Page Not Found',
        },
    },
};