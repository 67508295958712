// export const cdnBase = 'https://s3.amazonaws.com/candylockscdn/';
export const cdnBase = 'https://coolmaker2020.s3.amazonaws.com/'
// export const atgBase = process.env.NODE_ENV === 'development' ? 'https://staging.spinmasterstudios.com' : 'http://feed.spinmasterstudios.com/';

// export const atgBase = 'https://salsifystaging.spinmasterstudios.com/';
export const atgBase = 'https://prodfeed.spinmasterstudios.com/'; 

// Dec 17,2021: Please see below for sample dev & prod feeds
// DEV: https://salsifystaging.spinmasterstudios.com/rest/service/sm/us/catalog/ProductList?root=cat_coolmaker
// PROD: https://prodfeed.spinmasterstudios.com/rest/service/sm/us/catalog/ProductList?root=cat_coolmaker

export const atgBrand = 'cat_coolmaker';
export const salsifyBrand = 'cat_coolmaker';

export const atgProductDetailRoot = 'https://shop.spinmaster.com/product/';
export const UA = 'UA-102631505-38';
export const collectionServer = 'http://lampclusterlarge.spinmasterstudios.com:1337/collection/candylocks/';

// https://dock.spinmasterstudios.com/rest/service/sm/us/catalog/ProductList?root=cat_coolmaker
// http://prodfeed.spinmasterstudios.com/rest/service/sm/us/catalog/ProductList?root=cat_coolmaker&categories=true

// If you want to eject from react create app and not have to define these twice you could try this:
// https://github.com/nordnet/sass-variable-loader
// These are the same breaks as in _vars.sass
export const breakXSmall = 400;

export const breakSmall = 720;

export const breakTablet = 840;

export const breakLaptop = 1100;

export const breakXLaptop = 1600;

export const breakXLarge = 1600;


// To add a new locale, you musst add it on this list
export const siteMapByLocale = {
  'en_us': 'us',
  'en_ca': 'ca',
  'en_au': 'au',
  'en_gb': 'gb',
  'es_es': 'es',
  'it_it': 'it',
  'fr_fr': 'fr',
  'de_de': 'de',
  'ru_ru': 'ru',
  'nl_nl': 'nl',
  'mx_mx': 'mx'
  
};


// set to true if you want locale to display component
// export const localeBanlist={

// 	appSectionLocale: {
// 		en_us: true
// 	}
	
// }

// If you wish to review locales but not have them appear in the menu, add them here.
export const blockedLocales = [];

