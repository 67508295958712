import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MainMenu.css';
import { Link } from 'react-router-dom';
import { lang } from '../../../il8n/lang';
import { connect } from 'react-redux';
import { changeLanguage, changeActiveVid } from '../../../Actions/Index';

import localeIcon from './Images/icon_location.png';

import { blockedLocales, siteMapByLocale } from '../../../const';






/**
 * The main application menu.
 * @constructor
 */

class LocaleBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      menuClass: '',

    };


  }

  componentWillMount() {


  }

  componentWillUnmount() {


  }

  /**
  * Sets the language in redux.
  * @param {event} event
  */
  // setLang(event){
  //   this.props.dispatch(changeLanguage(event.target.value));
  //   this.props.dispatch(changeActiveVid(lang.videos.videoList[0]));
  //   window.location.replace(`/${lang.getLanguage()}`);
  // }

  setLang(event){

    let value = event.target.value;
    this.props.dispatch(changeLanguage(event.target.value));
    this.props.dispatch(changeActiveVid(lang.videos.videoList[0]));

    if (value in siteMapByLocale){
      window.location.replace(`/${lang.getLanguage()}`);
    } else {
      window.location.href = `http://intl.coolmaker.com/${value}/`; 
    }

    
  }

  /**
  * Returns all available languages to populate the menu.
  * @returns {jsx} - Menu options
  */
  getAvailableLanguages(){
    let options = [];

    let availableLangs = lang.getAvailableLanguages();
    for(let i = 0; i < availableLangs.length; i++){
      if(!blockedLocales.includes(availableLangs[i])){
        options.push(<option key={ i } value={ availableLangs[i] }>{ lang.langKeys[availableLangs[i]] }</option>)
      }
    }
    //international langs that haven't been migrated over to the new site
    // for(let i = 0; i < intlLocales.length; i++){
    //   console.log(intlLocales[i],"this is int local")
    //     // options.push(<option key={ i } value={ intlLocales[i] } >{ lang.langKeys[intlLocales[i]] }</option>)
    //   options.push( <a href="www.google.com" target='_blank' rel='noopener noreferrer' >
    //   <span>{ lang.langKeys[intlLocales[i]]}</span>
    // </a>)
    // }
    return options;
  }
  render() {
    let { menuClass } = this.state;
    return (
      <div className="localebar">
          <div className="wrap nav">
            <nav className='localebar__loc'>
              <img className='localebar__loc__icon' src={localeIcon} alt='location icon'/>
              <select className='localebar__loc__select' onChange={ this.setLang.bind(this) }>
                <option value='en'>{ lang.chooseRegion }</option>
                { this.getAvailableLanguages() }
              </select>
            </nav>
          </div>
        </div>

    );
  }
}

function mapStateToProps(state) {
   return { lang: state.lang };
}

LocaleBar.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(LocaleBar);
