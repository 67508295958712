import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MainMenu.css';
import { Link } from 'react-router-dom';
import { lang } from '../../../il8n/lang';
import { connect } from 'react-redux';
import { breakSmall, breakTablet, breakLaptop } from '../../../const';
import logo from './Images/logo.png';
import Localebar from './Localebar';

import { toggleInterstitial } from '../../../Actions/Index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes} from '@fortawesome/free-solid-svg-icons';
import { HashLink} from 'react-router-hash-link';

import Pink from './Images/PinkStroke_halfc.png';
import YoutubeIcon from './Images/YouTube-Icon-small.png';
import InstagramIcon from './Images/Instagram-Icon-small.png';
import FBIcon from './Images/facebook.png';



/**
 * The main application menu.
 * @constructor
 Props:

  - collaspe(boolean): toggle collapsing behaviour
  - centered(boolean): change placement of logo
 */

class MainMenu extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      menuClass: '',
      lastScrollTop: 0,
      activeClass: 'active'
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
    this.renderToggle = this.renderToggle.bind(this);

  }

  componentWillMount() {
    // When this component mounts, begin listening for scroll changes
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    // If this component is unmounted, stop listening
    window.removeEventListener('scroll', this.handleScroll);
  }



  /**
  * handle scroll
  * @param {event} event
  */

  handleScroll(e) {

    if(this.state.collapse){
      if (this.state.menuClass !== 'active') {
        const currentScrollTop = document.documentElement.scrollTop;

        // Set the state of hidden depending on scroll position
        // We only change the state if it needs to be changed
        if (currentScrollTop > 300 ) {
          this.setState({ menuClass: 'mod-hidden' });
        } else if(this.state.menuClass === 'mod-hidden') {
          this.setState({ menuClass: '' });
        }
        this.setState({ lastScrollTop: currentScrollTop });
      }
    }
    
  }
  /**
  * Toggles the main menu.
  * @param {string} requestor - Which element was clicked to initiate this call.
  */
  toggleMainMenu(togglesetting){
    let {menuClass} = this.state;
    if(window.innerWidth <= breakLaptop){
      if(menuClass === 'active'){
        this.setState({
          menuClass: ''
        });
        this.setState({
          toggleicon: 'hide'
        });
      } else {
        if(togglesetting !== "nomobile"){
          this.setState({
            menuClass: 'active'
          });
  
          this.setState({
            toggleicon: 'active'
          });
        }
        
      }
    }
  }

  renderToggle(){
    return(
      <nav className='main-menu__toggle' onClick={() => this.toggleMainMenu('logo')}>
        <FontAwesomeIcon className={`bars ${this.state.toggleicon}`} icon={faBars} />
        <FontAwesomeIcon className={`exit ${this.state.toggleicon}`} icon={faTimes} />
      </nav>
    )
  }
  /**
  * Renders either the centered layout or left aligned layout
  * @returns {jsx} - The nav item
  */
  renderMenu(){
    if (this.state.centered) {
      return(
        <div className='main-menu centered'>
          
          <nav className='main-menu__links'>
            <ul>
              {lang.navigation.centermenus[0].map((nav, index) => (
                this.getNavItem(index, nav)
              ))}
            </ul>

          </nav>
          <Link to={`/${lang.getLanguage()}`} className='main-menu__logo' onClick={() => this.toggleMainMenu('logo')}>
            <img className="mainlogo" src={logo} alt={lang.brand} />
            <img className="mobilelogo" src={logo} alt={lang.brand} />
          </Link>
          <nav className='main-menu__links'>
            <ul>
              {lang.navigation.centermenus[1].map((nav, index) => (
                this.getNavItem(index, nav)
              ))}
            </ul>
          </nav>
          {this.renderToggle()}
        </div>
      )
    }else{
      return(
        <div className='main-menu-wrapper'>
          <div className='main-menu'>
            <Link to={`/${lang.getLanguage()}`} className='main-menu__logo' onClick={() => this.toggleMainMenu('nomobile')}>
              <img className="mainlogo" src={logo} alt={lang.brand} />
              <img className="mobilelogo" src={logo} alt={lang.brand} />
            </Link>


          </div>

          {this.renderToggle()}

          <nav className='main-menu__links'>
              <ul>
                {lang.navigation.mainmenu.map((nav, index) => (
                  this.getNavItem(index, nav)
                ))}
                  <li className="social-icon-mobile">
                  <a onClick={() => {this.props.dispatch(toggleInterstitial(true, lang.navigation.youtube_link)); this.toggleMainMenu()} } rel='noopener noreferrer'>
                  <img className="youtube_icon" src={YoutubeIcon} alt={lang.navigation.youtube_alt} />
                  </a>
                  <a onClick={() => {this.props.dispatch(toggleInterstitial(true, lang.navigation.instagram_link)); this.toggleMainMenu()} } rel='noopener noreferrer'>
                  <img className="instagram_icon" src={InstagramIcon} alt={lang.navigation.instagram_alt} />
                  </a>
                  <a onClick={() => {this.props.dispatch(toggleInterstitial(true, lang.navigation.fb_link)); this.toggleMainMenu()} } rel='noopener noreferrer'>
                  <img className="instagram_icon" src={FBIcon} alt={lang.navigation.fb_alt} />
                  </a>
                  </li>
              </ul>

            </nav>
          <div className="social-icons">
            <a onClick={() => {this.props.dispatch(toggleInterstitial(true, lang.navigation.youtube_link)); this.toggleMainMenu()} } rel='noopener noreferrer'>
            <img className="youtube_icon" src={YoutubeIcon} alt={lang.navigation.youtube_alt} />
            </a>
            <a onClick={() => {this.props.dispatch(toggleInterstitial(true, lang.navigation.instagram_link)); this.toggleMainMenu()} } rel='noopener noreferrer'>
            <img className="instagram_icon" src={InstagramIcon} alt={lang.navigation.instagram_alt} />
            </a>
            <a onClick={() => {this.props.dispatch(toggleInterstitial(true, lang.navigation.fb_link)); this.toggleMainMenu()} } rel='noopener noreferrer'>
              <img className="instagram_icon" src={FBIcon} alt={lang.navigation.fb_alt} />
              </a>
          </div>
        </div>
      )
    }
  }
  /**
  * Gets the nav item. Supports 4 types in nav.type : 'internal','internal_hash','external','interstitial' & 'function'.
  * @param {int} index - Current item index.
  * @param {json} nav - Nav item properties.
  * @returns {jsx} - The nav item
  */
  getNavItem(index, nav){
    let {retailersModal} = this.props;
    let mobileOnly = nav.mobileOnly ? 'mobile' : ''
    switch (nav.type){
      case 'internal':
        return(
          <li key={`navigation-${ index }`} className={mobileOnly}>
            <Link to={ `/${lang.getLanguage()}${nav.href}` } onClick={() => this.toggleMainMenu()}>
              <span>{ nav.display }</span>
            </Link>
          </li>
        );
      case 'internal_hash':
        return(
          <li key={`navigation-${ index }`} className={mobileOnly}>
            <HashLink smooth to={ `/${lang.getLanguage()}${nav.href}` } onClick={() => this.toggleMainMenu()}>
              <span>{ nav.display }</span>
            </HashLink>
          </li>
        );
      case 'external':
        return(
          <li key={`navigation-${ index }`} className={mobileOnly}>
            <a href={ nav.href } target='_blank' rel='noopener noreferrer' onClick={() => this.toggleMainMenu()}>
              <span>{ nav.display }</span>
            </a>
          </li>
        );
      case 'interstitial':
        return(
          <li key={`navigation-${ index }`} className={mobileOnly}>
            <a onClick={() => {this.props.dispatch(toggleInterstitial(true, nav.href)); this.toggleMainMenu()} } rel='noopener noreferrer'>
              <span>{ nav.display }</span>
            </a>
          </li>
        );
      case 'function':
        return(
          <li key={`navigation-${ index }`} className={mobileOnly}>
            <a onClick={retailersModal} rel='noopener noreferrer'>
              <span>{ nav.display }</span>
            </a>
          </li>
        );
      default:
        console.error(new Error('Nav items must specify type.'));
    }
  }

  render() {
    let { menuClass } = this.state;
    return (
      <header className={ menuClass }>
        <Localebar/>
        <div className="wrap_large">
          <div className="pink_swoosh_container">
            <img className="pink_swoosh" src= { Pink } alt="pink swoosh" />
          </div>
        </div>
        <div className="wrap">
          {this.renderMenu()}

         
        </div>
      </header>
    );
  }
}

function mapStateToProps(state) {
   return { lang: state.lang };
}

MainMenu.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(MainMenu);
