import React, { Component } from 'react';
import { lang } from '../../il8n/lang';
import { connect } from 'react-redux';
import { toggleInterstitial } from '../../Actions/Index';
import ReactGA from 'react-ga';
import './Interstitial.css';

/**
 * Interstitial Component.
 * @constructor
 * @prop {json} interstitial - A redux driven JSON representation of this interstitials state and content.
 */

class Interstitial extends Component {
  /**
  * Navigate and track a links exit
  */
  navigateAndTrack(){
    let {interstitial} = this.props;
    ReactGA.event({ category: 'Retailer', action: 'Click', label: interstitial.label});
    window.open(
      interstitial.url,
      '_blank'
    );
    this.props.dispatch(toggleInterstitial(false, '', ''))
  }
  /**
  * Get interstitial content.
  * @return {jsx} The interstitial content.
  */
  getContent(){
    let {interstitial} = this.props;
    if(interstitial.active && !interstitial.isIframe){
      return (
        <div className="interstitial">
          <div className="interstitial-content">
            <div className="interstitial-close" onClick={() => this.props.dispatch(toggleInterstitial(false, '', ''))}/>
            {/* <div className='interstitial-close-new' onClick={() => this.props.dispatch(toggleInterstitial(false, '', ''))}>
                <svg viewBox="0 0 74.9 74.9" onClick={() => this.props.dispatch(toggleInterstitial(false, '', ''))} >
                  <rect x="6.1" y="41.1" width="87.9" height="18.05" transform="translate(37.5 -33.4) rotate(45)"/>
                  <rect x="6.1" y="41.1" width="87.9" height="18.05" transform="translate(108.3 37.5) rotate(135)"/>
                </svg>
              </div> */}
            <h2>{lang.interstitial.heading}<br />{lang.interstitial.heading1} </h2>
            <p>{lang.interstitial.copy}</p>
            <div className="interstial-links">
              <a onClick={() => this.navigateAndTrack()}>{lang.interstitial.continue}</a>
              <a onClick={() => this.props.dispatch(toggleInterstitial(false, '', ''))} target="_blank" rel="noopener noreferrer">{lang.interstitial.cancel}</a>
            </div>
          </div>
        </div>
      );
    }
    if(interstitial.active && interstitial.isIframe){
      return (
        <div className="interstitial">
          <div className="interstitial-content interstitial-content-iframe">
            <div className="interstitial-close" onClick={() => this.props.dispatch(toggleInterstitial(false, '', ''))}/>
            {/* <div className='interstitial-close-new' onClick={() => this.props.dispatch(toggleInterstitial(false, '', ''))}>
                <svg viewBox="0 0 74.9 74.9" onClick={() => this.props.dispatch(toggleInterstitial(false, '', ''))}>
                  <rect x="6.1" y="41.1" width="87.9" height="18.05" transform="translate(37.5 -33.4) rotate(45)"/>
                  <rect x="6.1" y="41.1" width="87.9" height="18.05" transform="translate(108.3 37.5) rotate(135)"/>
                </svg>
              </div> */}
            <iframe width="1000" height="550" src={interstitial.url} />
          </div>
        </div>
      );
    }
  }
  render() {
    return(
      <div>
        { this.getContent() }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang,
    interstitial: state.interstitial
  };
}

export default connect(mapStateToProps)(Interstitial);
